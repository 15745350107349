import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import Footer from "../../components/Footer";
import Navbar from "../../components/navbar/Navbar";
import '../../styles/blog.css';  // Import the new CSS file for blog-specific styles

export default function Blog() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    axios.get('https://api.mylondoncorner.com/posts', {
      headers: {
        'X-API-KEY': process.env.REACT_APP_API_KEY
      }
    })
    .then((response) => {
      setPosts(response.data);
      setLoading(false);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      setLoading(false);
    });
  }, []);

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>Blog | MyLondonCorner</title>
        <meta name="description" content="Scopri curiosità, consigli di viaggio e storie affascinanti su Londra. Idee originali per il tuo soggiorno nella capitale inglese." />
        <link rel="canonical" href="https://mylondoncorner.com/" />

        <meta name="keywords" content="Blog su Londra, Storie di Londra, Consigli di viaggio a Londra, Attrazioni di Londra, Cultura londinese, Luoghi segreti di Londra, Esperienze a Londra, mylondoncorner blog" />
        <meta name="robots" content="index, follow, max-image-preview:large" />
        
        {/* Open Graph Tags */}
        <meta property="og:title" content="Blog | MyLondonCorner" />
        <meta property="og:description" content="Scopri curiosità, consigli di viaggio e storie affascinanti su Londra. Idee originali per il tuo soggiorno nella capitale inglese." />
        <meta property="og:image" content="https://api.mylondoncorner.com/images/uploads/1721137490599--roaming.jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:url" content="https://mylondoncorner.com/" />
        <meta property="og:site_name" content="Blog | MyLondonCorner" />
        <meta property="og:locale" content="it_IT" />
        <meta property="og:type" content="website" />
        
        {/* Twitter Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@mylondoncorner" />
        <meta name="twitter:creator" content="@mylondoncorner" />
        <meta name="twitter:title" content="Blog | MyLondonCorner" />
        <meta name="twitter:description" content="Scopri curiosità, consigli di viaggio e storie affascinanti su Londra. Idee originali per il tuo soggiorno nella capitale inglese." />

      </Helmet>
      <Navbar />
      <div className="blog-container">
        <br />
        <h1>Blog</h1>
        <br />
        <p>Notizie, informazioni, dove mangiare a Londra, cose da fare, insomma, qui troverai tutto cio' che ti serve per il tuo viaggio a Londra!</p>
        <br />
        <br />
        {loading ? (
          <p>Loading...</p>
        ) : (
          <div className="posts">
            {posts.posts.map(post => (
              <div key={post.id} className="post" onClick={() => navigate(`/post/${post.slug}`)}>
                <img src={'https://api.mylondoncorner.com/' + post.image_path} alt={post.title} className="post-image"/>
                <p className='post-category'>{post.category[0].name} {post.category[1] && '/ ' + post.category[1].name}</p>
                <h2 className="post-title">{post.title}</h2>
              </div>
            ))}
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}
