import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Sitemap.css';

const Sitemap = () => {
  return (
    <div className="sitemap">
      <h1>Site Map</h1>
      <ul className="sitemap-list">
        <li><Link to="/">Home</Link></li>
        {/* <li>
          <Link to="/about">About Us</Link>
          <ul>
            <li><Link to="/about/team">Our Team</Link></li>
            <li><Link to="/about/history">Company History</Link></li>
          </ul>
        </li> */}
        <li>
          <Link to="/tours">Tour</Link>
          <ul>
            <li><Link to="/tour-di-londra">I nostri Tour</Link></li>
            <li><Link to="/tour-privati">Guide Private</Link></li>
            <li><Link to="/tour/tour-a-piedi-di-harry-potter-a-londra">Tour a piedi di Harry Potter a Londra: Visita ai Luoghi delle Riprese</Link></li>
            <li><Link to="/tour/tour-a-piedi-della-londra-iconica">Tour a piedi della Londra Iconica</Link></li>
            <li><Link to="/tour/tour-della-londra-instagrammabile-e-insolita">Tour a piedi della Londra Instagrammabile e Insolita</Link></li>
            <li><Link to="/tour/tour-delle-cotswolds-in-macchina">Tour delle Cotswolds privato in macchina</Link></li>
            <li><Link to="/tour/tour-di-londra-privato-in-macchina">Tour di Londra privato in macchina</Link></li>
            <li><Link to="/tour/tour-di-william-shakespeare-a-londra">Tour a piedi di William Shakespeare a Londra</Link></li>
            <li><Link to="/tour/tour-a-piedi-della-street-art-a-londra">Tour a piedi della Street Art a Londra</Link></li>
          </ul>
        </li>
        <li><Link to="/viaggi-di-gruppo">Viaggi di Gruppo</Link></li>
        <li>
          <Link to="/services">Servizi</Link>
          <ul>
            <li><Link to="/itinerari-personalizzati">Itinerari Personalizzati</Link></li>
            <li><Link to="/tour-privati">Guide Private</Link></li>
            <li><Link to="/taxi">Taxi tra Londra e aeroporti</Link></li>
          </ul>
        </li>
        <li><Link to="/blog">Blog</Link></li>
        <li><Link to="/basket">Carrello</Link></li>
        <li><Link to="/account">Account</Link></li>
        <li><Link to="/contact">Contatti</Link></li>
        <li><Link to="/legal/terms-and-conditions">Termini e Condizioni</Link></li>
        <li><Link to="/legal/privacy-policy">Privacy Policy</Link></li>
      </ul>
    </div>
  );
};

export default Sitemap;