import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';

// Icons
import { trashOutline, cartOutline, shieldCheckmarkOutline, chatbubbleEllipsesOutline, checkmarkCircleOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';

// Context
import { useAuth } from '../components/AuthContext';

// Components
import Navbar from '../components/navbar/Navbar';
import Footer from '../components/Footer';
import FeedbackPopup from '../components/widgets/FeedbackPopup';
import SliderTours from '../components/widgets/SliderTours';
import ContactForm from '../components/widgets/ContactForm';

// Styles
import '../styles/basket.css';

function Basket() {
  // Using AuthContext to maintain user authentication state across multiple components
  const { user } = useAuth();
  const navigate = useNavigate();

  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [basketData, setBasketData] = useState([]);
  const [loadingBasket, setLoadingBasket] = useState(true);

  const [tourData, setTourData] = useState([]);
  const [loadingTours, setLoadingTours] = useState(true);

  const [guestEmail, setGuestEmail] = useState();

  const [discountCode, setDiscountCode] = useState();

  const [totalPrice, setTotalPrice] = useState();

  useEffect(() => {
    // Make the API call when the component mounts
    axios.get('https://api.mylondoncorner.com/tours/category/name/I%20nostri%20Tour', {
      headers: {
        'X-API-KEY': process.env.REACT_APP_API_KEY // Include the API key in the request headers
      }
    })
    .then((response) => {
      setTourData(response.data.tours); // Update the data state with the API response
      setLoadingTours(false); // Set loading to false once data is fetched
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      setLoadingTours(false); // Set loading to false on error as well
    });
  }, []);

  useEffect(() => {
    let timeoutId;
  
    const fetchData = () => {
      if (user && user.uid) {
        setLoadingBasket(true);
        axios.get(`https://api.mylondoncorner.com/basket/${user.uid}`, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY // Include the API key in the request headers
          }
        })
        .then((response) => {
          setBasketData(response.data);
          setLoadingBasket(false);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          setLoadingBasket(false);
        });
      }
    };
  
    const refreshData = () => {
      timeoutId = setTimeout(() => {
        if (basketData.length === 0) {
          fetchData();
        }
      }, 1500); // Refresh after 1.5 second
    };
  
    fetchData(); // Initial fetch
    refreshData(); // Start the refresh loop
  
    return () => {
      clearTimeout(timeoutId); // Cleanup
    };
  }, [user, basketData.length]); // Dependency array includes basketData.length

  useEffect(() => {
    if(basketData.tourBasketItems) {
      setTotalPrice(basketData.tourBasketItems.reduce((total, item) => total + (item.quantity_adult * item.tour.price) + (item.quantity_child * item.tour.price_child), 0));
      setLoadingBasket(false);
    }
  }, [basketData]);

  // Handler function to remove an item from the basket
  const handleRemoveItem = (itemId) => {
    axios.get(`https://api.mylondoncorner.com/basket/remove/${itemId}`, {
        headers: {
          'X-API-KEY': process.env.REACT_APP_API_KEY // Include the API key in the request headers
        }
      })
      .then((response) => {
        // Refresh basket data after item removal
        // You may want to implement a more efficient way to update the basket data, like removing the item directly from the state
        axios.get(`https://api.mylondoncorner.com/basket/${user.uid}`, {
            headers: {
              'X-API-KEY': process.env.REACT_APP_API_KEY // Include the API key in the request headers
            }
          })
          .then((response) => {
            setBasketData(response.data);
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      })
      .catch((error) => {
        console.error('Error removing item:', error);
      });
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleCheckout = () => {
    if(user.isAnonymous && (!guestEmail || !validateEmail(guestEmail))) {
      setFeedbackMessage("Per favore, inserisci un indirizzo e-mail valido.");
      setIsPopupOpen(true);
      return;
    } 

    axios.get(`https://api.mylondoncorner.com/basket/checkout/${user.uid}/${guestEmail}/${discountCode}`, {
      headers: {
        'X-API-KEY': process.env.REACT_APP_API_KEY
      }
    })
    .then((response) => {
      const checkoutUrl = response.data.url;
      window.location.href = checkoutUrl;
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });
  };

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>Basket | MyLondonCorner</title>
        <meta name="description" content="Scopri Londra al massimo con i nostri esclusivi tour guidati in italiano. Visita luoghi nascosti, attrazioni imperdibili e vivi un'esperienza unica con mylondoncorner!" />
        <link rel="canonical" href="https://mylondoncorner.com/" />

        <meta name="keywords" content="Viaggi a Londra, Turismo a Londra, Attrazioni di Londra, Cose da fare a Londra, Guida di Londra, Vacanza a Londra, Visite turistiche a Londra, Hotel a Londra, Trasporti a Londra, Luoghi famosi di Londra, mylondoncorner" />
        <meta name="robots" content="index, follow, max-image-preview:large" />
        
        {/* Open Graph Tags */}
        <meta property="og:title" content="Basket | MyLondonCorner" />
        <meta property="og:description" content="Scopri Londra con i nostri esclusivi tour guidati in italiano. Visita luoghi nascosti, attrazioni imperdibili e vivi un'esperienza unica con mylondoncorner!" />
        <meta property="og:url" content="https://mylondoncorner.com/" />
        <meta property="og:site_name" content="Basket | MyLondonCorner" />
        <meta property="og:locale" content="it_IT" />
        <meta property="og:type" content="website" />
      </Helmet>
      <Navbar />
        <>
          <br />
          <div className="home-container">
            {loadingBasket ? (
              <>
                {user ? (
                  <>
                    <br />
                    <h1>
                      Carrello
                    </h1>
                    <br />
                    <p>
                      Prenditi un momento per controllare i dettagli qui sotto prima di procedere al pagamento.
                    </p>
                    <br /><br />
                    <div className="loading-animation"></div>
                    <SliderTours images={tourData} />
                  </>
                ) : (
                  <>
                    <br />
                    <h1>
                      Carrello
                    </h1>
                    <br />
                    <p>
                      Prenditi un momento per controllare i dettagli qui sotto prima di procedere al pagamento.
                    </p>
                    <br /><br />
                    <h3 style={{ textAlign: 'center' }}>Il tuo carrello è vuoto al momento!</h3>
                    <br />
                    <IonIcon icon={cartOutline} style={{ fontSize: '200px' }} />
                    <br /><br />
                    <button className='button' onClick={() => navigate('/tour-di-londra')}>Scopri i nostri tour</button>
                    <br /><br /><br />
                    <SliderTours images={tourData} />
                  </>
                )}
              </>
            ) : (
              <div>
                {basketData.tourBasketItems && basketData.tourBasketItems.length > 0 ? (
                  <>
                    <br />
                    <h1>
                      Carrello
                    </h1>
                    <br />
                    <p>
                      Prenditi un momento per controllare i dettagli qui sotto prima di procedere al pagamento.
                    </p>
                    <br /><br />
                    <div className='row'>
                      <div className='basket-left-column'>
                        {basketData.tourBasketItems.map((item, index) => (
                          <>
                            <div className='basket-item' key={index}>
                              <div className='basket-item-title'>
                                <img src={process.env.REACT_APP_API_URL + '/' + item.tour.images_paths[0]} />
                                <p style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                  {item.tour.name}
                                  <p style={{ fontSize: '16px', marginTop: '1%' }}>🕐 {formatDate(item.date)} • {item.tour.meeting_time}</p>
                                </p>
                              </div>
                              <br />
                              <p style={{ marginTop: '1%' }}>🎫 Lingua: Italiano</p>
                              <p style={{ marginTop: '1%' }}>
                                👥 {item.quantity_adult} {item.quantity_adult === 1 ? 'adulto' : 'adulti'}, {item.quantity_child} {item.quantity_child === 1 ? 'bambino' : 'bambini'}
                              </p>
                              <br />
                              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <button className='basket-delete-button' onClick={() => handleRemoveItem(item._id)}><IonIcon icon={trashOutline} /></button>
                                <p style={{ flex: '1', textAlign: 'right', marginLeft: '10px' }}>
                                  £{(item.tour.price * item.quantity_adult) + (item.tour.price_child * item.quantity_child)}
                                </p>
                              </div>
                            </div>
                            <br />
                          </>
                        ))}
                        <div className='basket-only-small-screen'>
                          <h3 style={{ textAlign: 'center', fontSize: '20px' }}>Riepilogo</h3>
                          <br />
                          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '18px' }}>
                            <p><b>Totale ({basketData.tourBasketItems.length === 1 ? basketData.tourBasketItems.length + ' tour' : basketData.tourBasketItems.length + ' tour' }):</b></p>
                            <p><b>£{totalPrice}</b></p>
                          </div>
                          <br />
                          <p>Tutte le tasse e le spese incluse</p>
                          {user && user.isAnonymous ? (
                            <>
                              <br />
                              <p><b>Inserisci la tua e-mail</b></p>
                              <input type='text' id="email" name="email" onChange={(e) => setGuestEmail(e.target.value)} style={{ padding: '10px', borderRadius: '10px', fontSize: '15px', border: '2px solid black' }} placeholder='esempio@esempio.com' />
                              <br />
                            </>
                          ) : null}
                          <br />
                          <p><b>Hai un codice sconto?</b></p>
                          <input type='text' id="discountCode" name="discountCode" onChange={(e) => setDiscountCode(e.target.value)} style={{ padding: '10px', borderRadius: '10px', fontSize: '15px', border: '2px solid black' }} />
                          <br /><br />
                          <hr />
                          <br />
                          <button className='button' onClick={handleCheckout}>Vai al pagamento</button>
                          <br />
                          <p style={{ display: 'flex', alignItems: 'center', textAlign: 'left', gap: '8px' }}>
                            <IonIcon icon={checkmarkCircleOutline} style={{ fontSize: '30px', flexShrink: 0, color: 'green' }} />
                            Cancellazione gratuita entro 72 ore dal tour
                          </p>
                        </div>
                        <br /><br />
                        <div className='basket-only-small-screen'>
                          <h3 style={{ textAlign: 'center', fontSize: '20px' }}>Perché prenotare con noi?</h3>
                          <br />
                          <p style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <IonIcon icon={shieldCheckmarkOutline} style={{ fontSize: '30px' }} />
                            Pagamenti sicuri
                          </p>
                          <br />
                          <p style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <IonIcon icon={cartOutline} style={{ fontSize: '30px' }} />
                            Nessun costo aggiuntivo
                          </p>
                          <br />
                          <p style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <IonIcon icon={chatbubbleEllipsesOutline} style={{ fontSize: '30px' }} />
                            Supporto in italiano
                          </p>
                        </div>
                      </div>
                      <div className='basket-right-column'>
                        <div className='basket-checkout-box'>
                          <h3 style={{ textAlign: 'center', fontSize: '20px' }}>Riepilogo</h3>
                          <br />
                          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '18px' }}>
                            <p>Totale ({basketData.tourBasketItems.length === 1 ? basketData.tourBasketItems.length + ' tour' : basketData.tourBasketItems.length + ' tour' }):</p>
                            <p>£{totalPrice}</p>
                          </div>
                          <br />
                          <p>Tutte le tasse e le spese incluse</p>
                          {user && user.isAnonymous ? (
                            <>
                              <br />
                              <p><b>Inserisci la tua e-mail</b></p>
                              <input type='text' id="email" name="email" onChange={(e) => setGuestEmail(e.target.value)} style={{ padding: '10px', borderRadius: '10px', fontSize: '15px', border: '2px solid black' }} placeholder='esempio@esempio.com' />
                              <br />
                            </>
                          ) : null}
                          <br />
                          <p><b>Hai un codice sconto?</b></p>
                          <input type='text' id="discountCode" name="discountCode" onChange={(e) => setDiscountCode(e.target.value)} style={{ padding: '10px', borderRadius: '10px', fontSize: '15px', border: '2px solid black' }} />
                          <br /><br />
                          <hr />
                          <br />
                          <button className='button' onClick={handleCheckout}>Vai al pagamento</button>
                          <br />
                          <p style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <IonIcon icon={checkmarkCircleOutline} style={{ fontSize: '30px', flexShrink: 0, color: 'green' }} />
                            Cancellazione gratuita entro 72 ore dal tour
                          </p>
                        </div>
                        <br />
                        <div className='basket-checkout-box'>
                          <h3 style={{ textAlign: 'center', fontSize: '20px' }}>Perché prenotare con noi?</h3>
                          <br />
                          <p style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <IonIcon icon={shieldCheckmarkOutline} style={{ fontSize: '30px' }} />
                            Pagamenti sicuri
                          </p>
                          <br />
                          <p style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <IonIcon icon={cartOutline} style={{ fontSize: '30px' }} />
                            Nessun costo aggiuntivo
                          </p>
                          <br />
                          <p style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <IonIcon icon={chatbubbleEllipsesOutline} style={{ fontSize: '30px' }} />
                            Supporto in italiano
                          </p>
                        </div>
                      </div>
                    </div>
                    <br /><br /><br />
                    <h2 style={{ textAlign: 'left' }}>Ti potrebbe piacere anche...</h2>
                    <br /><br />
                    <SliderTours images={tourData} />
                  </>
                ) : (
                  <>
                    <br />
                    <h1>
                      Carrello
                    </h1>
                    <br />
                    <p>
                      Prenditi un momento per controllare i dettagli qui sotto prima di procedere al pagamento.
                    </p>
                    <br /><br />
                    <h3 style={{ textAlign: 'center' }}>Il tuo carrello è vuoto al momento!</h3>
                    <br />
                    <IonIcon icon={cartOutline} style={{ fontSize: '200px' }} />
                    <br /><br />
                    <button className='button' onClick={() => navigate('/tour-di-londra')}>Scopri i nostri tour</button>
                    <br /><br /><br />
                    <SliderTours images={tourData} />
                  </>
                )}
              </div>
            )}
          </div>
        </>
      <br /><br />
      <ContactForm />
      <br />
      <Footer />
      <FeedbackPopup
        isOpen={isPopupOpen}
        message={feedbackMessage}
        onClose={() => setIsPopupOpen(false)}
        onConfirm={() => setIsPopupOpen(false)}
        confirmButtonText="OK"
      />
    </>
  );
}

// Define a function to format the date
function formatDate(dateString) {
  const date = new Date(dateString);
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const year = date.getUTCFullYear();
  return `${day}/${month}/${year}`;
}

export default Basket;
