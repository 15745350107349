import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import '../../styles/components/widgets/GoogleReviews.css';

// import required modules
import { Pagination, Navigation } from 'swiper/modules';

export default function SliderGoogleReviews() {
  return (
    <>
      <div className='google-reviews'>
        <h2>Le nostre recensioni su Google</h2>
        <div>
          <span className="star filled google-reviews-header-stars">&#9733;</span>
          <span className="star filled google-reviews-header-stars">&#9733;</span>
          <span className="star filled google-reviews-header-stars">&#9733;</span>
          <span className="star filled google-reviews-header-stars">&#9733;</span>
          <span className="star filled google-reviews-header-stars">&#9733;</span>
          <span>&nbsp;</span>
          <span className='google-reviews-header-text'>5.0 stelle su 57 recensioni</span>
        </div>
        <br /><br />
        <a href='https://www.google.com/search?q=mylondoncorner#mpd=~12952344745211182969/customers/reviews'>
          <Swiper
            slidesPerView={1.5}
            spaceBetween={30}
            loop={false}
            pagination={{
              clickable: true,
            }}
            navigation={false}
            modules={[Pagination, Navigation]}
            breakpoints={{
              // When the screen width is 1200px or larger
              1200: {
                slidesPerView: 4.1, // Show 3 slides on larger screens
                spaceBetween: 30, // Adjust space between slides
              },
              750: {
                slidesPerView: 3.1, // Show 3 slides on larger screens
                spaceBetween: 30, // Adjust space between slides
              },
            }}
          >
            <SwiperSlide className='google-review-card'>
              <img src='https://ui-avatars.com/api/?name=Martina+Alfano&background=8B8B8B&color=fff' className='google-review-card-user-img' />
              <p><b>Martina Alfano</b></p>
              <p style={{ color: 'gray' }}>17/01/2024</p>
              <div className="google-review-rating">
                <span className="star filled">&#9733;</span>
                <span className="star filled">&#9733;</span>
                <span className="star filled">&#9733;</span>
                <span className="star filled">&#9733;</span>
                <span className="star filled">&#9733;</span>
              </div>
              <p className='google-review-review'>Abbiamo prenotato un tour privato di Londra di due giorni. Flora si è dimostrata disponibile e di estrema gentilezza fin dalle prime fasi della prenotazione. La nostra guida Jess ci ha stupiti con la sua estrema professionalità, simpatia, gentilezza e ci ha fatto scoprire Londra in un tour che non dimenticheremo mai! Esperienza stupenda consiglio vivamente!</p>
              <img src='https://upload.wikimedia.org/wikipedia/commons/2/2f/Google_2015_logo.svg' className='google-review-card-gg-img' />
            </SwiperSlide>
            <SwiperSlide className='google-review-card'>
              <img src='https://ui-avatars.com/api/?name=Giada+Ferrari&background=8B8B8B&color=fff' className='google-review-card-user-img' />
              <p><b>Giada Ferrari</b></p>
              <p style={{ color: 'gray' }}>14/01/2024</p>
              <div className="google-review-rating">
                <span className="star filled">&#9733;</span>
                <span className="star filled">&#9733;</span>
                <span className="star filled">&#9733;</span>
                <span className="star filled">&#9733;</span>
                <span className="star filled">&#9733;</span>
              </div>
              <p className='google-review-review'>Con My London Corner abbiamo scoperto posti e location che hanno ispirato la Rowling e in cui hanno girato delle scene iconiche di Garry Potter, è stato bellissimo e super emozionante. Grazie a Flora che ci mette tutta se stessa e traspare tutta la sua passione per questo lavoro ! ❤️</p>
              <img src='https://upload.wikimedia.org/wikipedia/commons/2/2f/Google_2015_logo.svg' className='google-review-card-gg-img' />
            </SwiperSlide>
            <SwiperSlide className='google-review-card'>
              <img src='https://ui-avatars.com/api/?name=Francesco+Esposito&background=8B8B8B&color=fff' className='google-review-card-user-img' />
              <p><b>Francesco Esposito</b></p>
              <p style={{ color: 'gray' }}>11/01/2024</p>
              <div className="google-review-rating">
                <span className="star filled">&#9733;</span>
                <span className="star filled">&#9733;</span>
                <span className="star filled">&#9733;</span>
                <span className="star filled">&#9733;</span>
                <span className="star filled">&#9733;</span>
              </div>
              <p className='google-review-review'>Grazie mille a MY LONDON CORNER Per questa fantastica esperienza. Bellissimo e indimenticabile il Tour con Flora a Londra e quello fuori città di Stonehenge.</p>
              <img src='https://upload.wikimedia.org/wikipedia/commons/2/2f/Google_2015_logo.svg' className='google-review-card-gg-img' />
            </SwiperSlide>
            <SwiperSlide className='google-review-card'>
              <img src='https://ui-avatars.com/api/?name=Antonia+Devoto&background=8B8B8B&color=fff' className='google-review-card-user-img' />
              <p><b>Antonia Devoto</b></p>
              <p style={{ color: 'gray' }}>10/12/2024</p>
              <div className="google-review-rating">
                <span className="star filled">&#9733;</span>
                <span className="star filled">&#9733;</span>
                <span className="star filled">&#9733;</span>
                <span className="star filled">&#9733;</span>
                <span className="star filled">&#9733;</span>
              </div>
              <p className='google-review-review'>HO TROPPO AMATO IL TOUR PRIVATO DI LONDRA, che ci ha organizzato Flora! Ho contattato mylondoncorner per la mia prima volta a Londra e io e il mio fidanzato siamo rimasti super contenti e soddisfatti. In 7 ore di tour abbiamo visto i posti più iconici di Londra e scoperto tante curiosità. Siamo entrati nella national gallery e devo dire che senza guida era IMPOSSIBILE visitarla e trovare i quadri più importanti. UN TOUR OPERATOR SUPER CONSIGLIATO e soprattutto mi affiderò nuovamente a loro per un prossimo viaggio e magari per qualche tour anche fuori la capitale. GRAZIE MILLE .. Antonia 💖</p>
              <img src='https://upload.wikimedia.org/wikipedia/commons/2/2f/Google_2015_logo.svg' className='google-review-card-gg-img' />
            </SwiperSlide>
            <SwiperSlide className='google-review-card'>
              <img src='https://ui-avatars.com/api/?name=Vittoria+Bernardini&background=8B8B8B&color=fff' className='google-review-card-user-img' />
              <p><b>Vittoria Bernardini</b></p>
              <p style={{ color: 'gray' }}>29/11/2024</p>
              <div className="google-review-rating">
                <span className="star filled">&#9733;</span>
                <span className="star filled">&#9733;</span>
                <span className="star filled">&#9733;</span>
                <span className="star filled">&#9733;</span>
                <span className="star filled">&#9733;</span>
              </div>
              <p className='google-review-review'>La nostra guida per il tour di harry potter gelsomina è stata perfetta, geniale e preparata-vittoria e andrea💛❤️</p>
              <img src='https://upload.wikimedia.org/wikipedia/commons/2/2f/Google_2015_logo.svg' className='google-review-card-gg-img' />
            </SwiperSlide>
            <SwiperSlide className='google-review-card'>
              <img src='https://ui-avatars.com/api/?name=Antonella+Lippolis&background=8B8B8B&color=fff' className='google-review-card-user-img' />
              <p><b>Antonella Lippolis</b></p>
              <p style={{ color: 'gray' }}>28/11/2024</p>
              <div className="google-review-rating">
                <span className="star filled">&#9733;</span>
                <span className="star filled">&#9733;</span>
                <span className="star filled">&#9733;</span>
                <span className="star filled">&#9733;</span>
                <span className="star filled">&#9733;</span>
              </div>
              <p className='google-review-review'>Ottima esperienza: organizzazione eccellente, gentilezza ed entusiasmo hanno accompagnato tutta l'esperienza di viaggio, a partire dal primo contatto informativo. Siamo felicissimi di aver vissuto il tour a tema Harry potter con voi. Entusiasmante ⚡️💫 ancora grazie✨️</p>
              <img src='https://upload.wikimedia.org/wikipedia/commons/2/2f/Google_2015_logo.svg' className='google-review-card-gg-img' />
            </SwiperSlide>
            <SwiperSlide className='google-review-card'>
              <img src='https://ui-avatars.com/api/?name=Alessandra+Catino&background=8B8B8B&color=fff' className='google-review-card-user-img' />
              <p><b>Alessandra Catino</b></p>
              <p style={{ color: 'gray' }}>10/11/2024</p>
              <div className="google-review-rating">
                <span className="star filled">&#9733;</span>
                <span className="star filled">&#9733;</span>
                <span className="star filled">&#9733;</span>
                <span className="star filled">&#9733;</span>
                <span className="star filled">&#9733;</span>
              </div>
              <p className='google-review-review'>ottima esperienza, guida simpatica, professionale e preparata. Sicuramente da consigliare</p>
              <img src='https://upload.wikimedia.org/wikipedia/commons/2/2f/Google_2015_logo.svg' className='google-review-card-gg-img' />
            </SwiperSlide>
            <SwiperSlide className='google-review-card'>
              <img src='https://ui-avatars.com/api/?name=Laura+Bedeschi&background=8B8B8B&color=fff' className='google-review-card-user-img' />
              <p><b>Laura Bedeschi</b></p>
              <p style={{ color: 'gray' }}>18/09/2024</p>
              <div className="google-review-rating">
                <span className="star filled">&#9733;</span>
                <span className="star filled">&#9733;</span>
                <span className="star filled">&#9733;</span>
                <span className="star filled">&#9733;</span>
                <span className="star filled">&#9733;</span>
              </div>
              <p className='google-review-review'>Io i miei bambini di 12 e 8 anni ed i nostri amici siamo stati accompagnati da Gelsomina nei luoghi più iconici di Londra, simpatica e gentile ci ha spiegato tante cose interessanti delle quali ci resterà sempre un bellissimo ricordo. Tour consigliatissimo.</p>
              <img src='https://upload.wikimedia.org/wikipedia/commons/2/2f/Google_2015_logo.svg' className='google-review-card-gg-img' />
            </SwiperSlide>
            <SwiperSlide className='google-review-card'>
              <img src='https://ui-avatars.com/api/?name=Rossanna+Arci&background=8B8B8B&color=fff' className='google-review-card-user-img' />
              <p><b>Rossanna Arci</b></p>
              <p style={{ color: 'gray' }}>21/08/2024</p>
              <div className="google-review-rating">
                <span className="star filled">&#9733;</span>
                <span className="star filled">&#9733;</span>
                <span className="star filled">&#9733;</span>
                <span className="star filled">&#9733;</span>
                <span className="star filled">&#9733;</span>
              </div>
              <p className='google-review-review'>Flora eccezionale e preparatissima! Le bambine si sono innamorate durante il tour delle location di Harry Potter. Sono il top!</p>
              <img src='https://upload.wikimedia.org/wikipedia/commons/2/2f/Google_2015_logo.svg' className='google-review-card-gg-img' />
            </SwiperSlide>
            <SwiperSlide className='google-review-card'>
              <img src='https://ui-avatars.com/api/?name=Francesca+Mastroscianni&background=8B8B8B&color=fff' className='google-review-card-user-img' />
              <p><b>Francesca Mastroscianni</b></p>
              <p style={{ color: 'gray' }}>18/08/2024</p>
              <div className="google-review-rating">
                <span className="star filled">&#9733;</span>
                <span className="star filled">&#9733;</span>
                <span className="star filled">&#9733;</span>
                <span className="star filled">&#9733;</span>
                <span className="star filled">&#9733;</span>
              </div>
              <p className='google-review-review'>Abbiamo fatto il tour “La Londra babbana” con Gelsomina, è stato molto bello perché oltre a far vedere diverse location dei film mostra anche angoli di Londra che spesso non si visitano. Consigliato!!</p>
              <img src='https://upload.wikimedia.org/wikipedia/commons/2/2f/Google_2015_logo.svg' className='google-review-card-gg-img' />
            </SwiperSlide>
            <SwiperSlide className='google-review-card'>
              <img src='https://ui-avatars.com/api/?name=Silvia+Cattaneo&background=8B8B8B&color=fff' className='google-review-card-user-img' />
              <p><b>Silvia Cattaneo</b></p>
              <p style={{ color: 'gray' }}>16/08/2024</p>
              <div className="google-review-rating">
                <span className="star filled">&#9733;</span>
                <span className="star filled">&#9733;</span>
                <span className="star filled">&#9733;</span>
                <span className="star filled">&#9733;</span>
                <span className="star filled">&#9733;</span>
              </div>
              <p className='google-review-review'>Ho partecipato al viaggio "Oxford e Londra" a tema Harry Potter... Esperienza unica e organizzazione stellare. Non è stato un semplice tour a tema Harry Potter ma un bellissimo tour di Londra e Oxford, nel quale Flora e Alessandro, oltre a fornire la loro assistenza e competenza, ci hanno illuminato con molte curiosità e aneddoti sui luoghi e sulla cultura e le abitudini dei locals</p>
              <img src='https://upload.wikimedia.org/wikipedia/commons/2/2f/Google_2015_logo.svg' className='google-review-card-gg-img' />
            </SwiperSlide>
          </Swiper>
        </a>
      </div>
    </>
  );
}