import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../components/AuthContext';
import { useNavigate } from 'react-router-dom';

import Footer from "../../components/Footer";
import Navbar from "../../components/navbar/Navbar";
import NotFound from '../../components/NotFound';

import '../../styles/admin/adminPanel.css';

function AdminPanel() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [userData, setUserData] = useState({});
  const [loadingUserData, setLoadingUserData] = useState({});

  const [tourBookingsData, setTourBookingsData] = useState({});
  const [loadingtourBookingsData, setLoadingTourBookingsData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      if (user && user.uid) {
        try {
          setLoadingUserData(true);
          const response = await axios.get(`https://api.mylondoncorner.com/user/${user.uid}`, {
            headers: {
              'X-API-KEY': process.env.REACT_APP_API_KEY
            }
          });
          setUserData(response.data);
          setLoadingUserData(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoadingUserData(false);
        }
      } else {
        console.log("User is not authenticated or user data is not available.");
      }
    };

    fetchData();
  }, [user]);

  useEffect(() => {
    setLoadingTourBookingsData(true);
    axios.get(`https://api.mylondoncorner.com/tourbookings/fromtoday`, {
      headers: {
        'X-API-KEY': process.env.REACT_APP_API_KEY
      }
    })
    .then((response) => {
      setTourBookingsData(response.data);
      setLoadingTourBookingsData(false);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      setLoadingTourBookingsData(false);
    });
  }, []);

  const getBackgroundColor = (status) => {
    switch (status) {
      case 'pending':
        return '#FFA500';
      case 'cancelled':
        return '#FF6347';
      case 'completed':
        return '#4CAF50';
      default:
        return 'white';
    }
  };

  return (
    <>
      {loadingUserData ? (
        <NotFound />
      ) : (
        Object.keys(userData).length !== 0 ? (
          userData.user.type === 4324143543 ? (
            <>
              <Navbar />

              {loadingtourBookingsData ? (
                <div className="loading-animation"></div>
              ) : (
                <>
                  <br />
                  <div className='row'>
                    <div className='admin-panel-column-left'>
                      <h2>Admin Panel</h2>

                      <br />

                      <h3>Tour</h3>

                      <br />

                      <a href="#!" onClick={(e) => { e.preventDefault(); navigate('/admin/tours'); }}>
                        <p className='admin-panel-h3'>
                          Tours
                        </p>
                      </a>

                      <br />

                      <a href="#!" onClick={(e) => { e.preventDefault(); navigate('/admin/tour-bookings'); }}>
                        <p className='admin-panel-h3'>
                          Tour Bookings
                        </p>
                      </a>

                      <br />

                      <h3>Discount Code</h3>

                      <br />

                      <a href="#!" onClick={(e) => { e.preventDefault(); navigate('/admin/discount-codes'); }}>
                        <p className='admin-panel-h3'>
                          Discount Codes
                        </p>
                      </a>

                      <br />

                      <h3>Trip</h3>

                      <br />

                      <a href="#!" onClick={(e) => { e.preventDefault(); navigate('/admin/trips'); }}>
                        <p className='admin-panel-h3'>
                          Trips
                        </p>
                      </a>

                      <br />

                      <a href="#!" onClick={(e) => { e.preventDefault(); navigate('/admin/messages'); }}>
                        <h3 className='admin-panel-h3'>
                          Messages
                        </h3>
                      </a>

                      <br />

                      <a href="#!" onClick={(e) => { e.preventDefault(); navigate('/admin/todos'); }}>
                        <h3 className='admin-panel-h3'>
                          Todos
                        </h3>
                      </a>

                      <br />

                      <a href="#!" onClick={(e) => { e.preventDefault(); navigate('/admin/searches'); }}>
                        <h3 className='admin-panel-h3'>
                          Searches
                        </h3>
                      </a>

                      <br />

                      <a href="#!" onClick={(e) => { e.preventDefault(); navigate('/admin/users'); }}>
                        <h3 className='admin-panel-h3'>
                          Users
                        </h3>
                      </a>

                      <br />

                      <a href="#!" onClick={(e) => { e.preventDefault(); navigate('/admin/newsletter/users'); }}>
                        <h3 className='admin-panel-h3'>
                          Newsletter Users
                        </h3>
                      </a>

                      <br />

                      <a href="#!" onClick={(e) => { e.preventDefault(); navigate('/admin/newsletter/send'); }}>
                        <h3 className='admin-panel-h3'>
                          Send Newsletter
                        </h3>
                      </a>

                      <br />

                      <a href="#!" onClick={(e) => { e.preventDefault(); navigate('/admin/posts'); }}>
                        <h3 className='admin-panel-h3'>
                          Posts
                        </h3>
                      </a>

                      <br />

                      <a href="#!" onClick={(e) => { e.preventDefault(); navigate('/admin/tour-basket-items'); }}>
                        <h3 className='admin-panel-h3'>
                          Tour Basket Items 
                        </h3>
                      </a>

                      <br />

                      <a href="#!" onClick={(e) => { e.preventDefault(); navigate('/admin/send-email'); }}>
                        <h3 className='admin-panel-h3'>
                          Send Email
                        </h3>
                      </a>

                      <br />
                      <hr />
                      <br />

                      <a href="https://portal.goldentours.com/en/login/agent" target="_blank">
                        <h3 className='admin-panel-h3'>
                            Golden Tours Panel
                        </h3>
                      </a>

                      <br />

                      <a href="https://supplier.getyourguide.com/home" target="_blank">
                        <h3 className='admin-panel-h3'>
                            Get Your Guide Supplier Panel
                        </h3>
                      </a>

                      <br />

                      <a href="https://partner.getyourguide.com/en-us/booking" target="_blank">
                        <h3 className='admin-panel-h3'>
                            Get Your Guide Ticket Sales Panel
                        </h3>
                      </a>

                      <br />

                      <a href="https://www.ratehawk.com/" target="_blank">
                        <h3 className='admin-panel-h3'>
                          Ratehawk Panel
                        </h3>
                      </a>

                      <br />

                      <a href="https://www.civitatis.com/it/affiliati/v2/inizio/" target="_blank">
                        <h3 className='admin-panel-h3'>
                          Civitatis Panel
                        </h3>
                      </a>

                      <br />

                      <a href="https://app.travelpayouts.com/dashboard" target="_blank">
                        <h3 className='admin-panel-h3'>
                          Travelpayouts Panel
                        </h3>
                      </a>

                      <br />

                      <a href="https://partners.viator.com/login" target="_blank">
                        <h3 className='admin-panel-h3'>
                          Viator Panel
                        </h3>
                      </a>

                      <br />

                      <a href="https://invoiced.network" target="_blank">
                        <h3 className='admin-panel-h3'>
                            Invoicing Software
                        </h3>
                      </a>
                    </div>
                    <div className='admin-panel-column-right'>
                      <table>
                        <tr>
                          <th>Image</th>
                          <th>Customer E-mail</th>
                          <th>Tour</th>
                          <th>Tour Guide</th>
                          <th>Duration</th>
                          <th>Tour Date</th>
                          <th>People</th>
                          <th>Notes</th>
                        </tr>
                        {tourBookingsData.tourBookings.map((tourBooking, index) => (
                            <tr key={index} style={{ backgroundColor: getBackgroundColor(tourBooking.status) }}>
                              <td><a href={`/tour/voucher/${tourBooking._id}`}><img src={'https://api.mylondoncorner.com/' + tourBooking.tour.images_paths[0]} style={{ width: '80px' }} /></a></td>
                              <td>{tourBooking.user ? tourBooking.user.email : tourBooking.guestEmail ? tourBooking.guestEmail : tourBooking.externalEmail}</td>
                              <td>{tourBooking.tour.name}</td>
                              <td><b>{tourBooking.tourGuide.name}</b></td>
                              <td>{tourBooking.duration ? tourBooking.duration + ' ore' : tourBooking.tour.duration}</td>
                              <td><b>{formatDate(tourBooking.date)}</b></td>
                              <td>{tourBooking.quantity_adult + tourBooking.quantity_child}</td>
                              <td>{tourBooking.notes}</td>
                            </tr>
                        ))}
                      </table>
                    </div>
                  </div>
                </>
              )}

              <Footer />
            </>
          ) : (
            <NotFound />
          )
        ) : (
          <NotFound />
        )
      )}
    </>
  );
}

// Define a function to format the date
function formatDate(dateString) {
  const date = new Date(dateString);
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const year = date.getUTCFullYear();
  return `${day}/${month}/${year}`;
}

export default AdminPanel;
