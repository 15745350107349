import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

import { useAuth } from '../../components/AuthContext';

import Footer from "../../components/Footer";
import Navbar from "../../components/navbar/Navbar";
import TermsCheckbox from "../../components/widgets/TermsCheckbox";

import '../../styles/trip/tripBook.css';

function TripBook() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { slug } = useParams(); // Get the slug from URL parameters

  const [tripData, setTripData] = useState([]);
  const [loadingTrip, setLoadingTrip] = useState(true);

  const [participants, setParticipants] = useState([]);

  const [adult, setAdult] = useState(1);
  const [children, setChildren] = useState(0);
  const [newborn, setNewborn] = useState(0);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [passport, setPassport] = useState('');
  const [flight, setFlight] = useState('');
  const [flightFrom, setFlightFrom] = useState('');
  const [transfers, setTransfers] = useState('');
  const [influencer, setInfluencer] = useState('');

  const [termsAndConditions, setTermsAndConditions] = useState(false);

  const handleAdultChange = (event) => {
    setAdult(parseInt(event.target.value));
  };

  const handleChildrenChange = (event) => {
    setChildren(parseInt(event.target.value));
  };

  const handleNewbornChange = (event) => {
    setNewborn(parseInt(event.target.value));
  };

  const handleNameChange = (event, index, type) => {
    const updatedParticipants = [...participants];
    if (!updatedParticipants[index]) {
      updatedParticipants[index] = { name: '', surname: '', dob: '', type: '', birthPlace: '', taxId: '', address: '', passport: '' };
    }
    updatedParticipants[index].name = event.target.value;
    updatedParticipants[index].type = type;
    setParticipants(updatedParticipants);
  };
  
  const handleSurnameChange = (event, index, type) => {
    const updatedParticipants = [...participants];
    if (!updatedParticipants[index]) {
      updatedParticipants[index] = { name: '', surname: '', dob: '', type: '', birthPlace: '', taxId: '', address: '', passport: '' };
    }
    updatedParticipants[index].surname = event.target.value;
    updatedParticipants[index].type = type;
    setParticipants(updatedParticipants);
  };
  
  const handleDobChange = (event, index, type) => {
    const updatedParticipants = [...participants];
    if (!updatedParticipants[index]) {
      updatedParticipants[index] = { name: '', surname: '', dob: '', type: '', birthPlace: '', taxId: '', address: '', passport: '' };
    }
    updatedParticipants[index].dob = new Date(event.target.value);
    updatedParticipants[index].type = type;
    setParticipants(updatedParticipants);
  };

  const handleBirthPlaceChange = (event, index, type) => {
    const updatedParticipants = [...participants];
    if (!updatedParticipants[index]) {
      updatedParticipants[index] = { name: '', surname: '', dob: '', type: '', birthPlace: '', taxId: '', address: '', passport: '' };
    }
    updatedParticipants[index].birthPlace = event.target.value;
    updatedParticipants[index].type = type;
    setParticipants(updatedParticipants);
  };

  const handleTaxIdChange = (event, index, type) => {
    const updatedParticipants = [...participants];
    if (!updatedParticipants[index]) {
      updatedParticipants[index] = { name: '', surname: '', dob: '', type: '', birthPlace: '', taxId: '', address: '', passport: '' };
    }
    updatedParticipants[index].taxId = event.target.value;
    updatedParticipants[index].type = type;
    setParticipants(updatedParticipants);
  };

  const handleAddressChange = (event, index, type) => {
    const updatedParticipants = [...participants];
    if (!updatedParticipants[index]) {
      updatedParticipants[index] = { name: '', surname: '', dob: '', type: '', birthPlace: '', taxId: '', address: '', passport: '' };
    }
    updatedParticipants[index].address = event.target.value;
    updatedParticipants[index].type = type;
    setParticipants(updatedParticipants);
  };

  const handlePassportChange = (event, index, type) => {
    const updatedParticipants = [...participants];
    if (!updatedParticipants[index]) {
      updatedParticipants[index] = { name: '', surname: '', dob: '', type: '', birthPlace: '', taxId: '', address: '', passport: '' };
    }
    updatedParticipants[index].passport = event.target.value;
    updatedParticipants[index].type = type;
    setParticipants(updatedParticipants);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const handleFlightChange = (event) => {
    setFlight(event.target.value);
  };

  const handleFlightFromChange = (event) => {
    setFlightFrom(event.target.value);
  };

  const handleTransfersChange = (event) => {
    setTransfers(event.target.value);
  };

  const handleInfluencerChange = (event) => {
    setInfluencer(event.target.value);
  };
  
  const handleTermsCheckboxChange = (isChecked) => {
    setTermsAndConditions(isChecked);
  };

  useEffect(() => {
    setLoadingTrip(true);
    axios.get(`https://api.mylondoncorner.com/trip/${slug}`, {
        headers: {
          'X-API-KEY': process.env.REACT_APP_API_KEY // Include the API key in the request headers
        }
      })
      .then((response) => {
        setTripData(response.data);
        setLoadingTrip(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoadingTrip(false);
      });
  }, [slug]);

  const handleButtonClick = () => {
    if(termsAndConditions && phone !== '' && email !== '' && passport !== '' && flight !== '' && flightFrom !== '' && transfers != '' && email.includes('@')) {
      axios.post('https://api.mylondoncorner.com/trip/send-booking-request', {
        trip: tripData.trip,
        participants: participants,
        email: email,
        phone: phone,
        passport: passport,
        flight: flight,
        flightFrom: flightFrom,
        transfers: transfers,
        influencer: influencer
      }, {
        headers: {
          'X-API-KEY': process.env.REACT_APP_API_KEY
        }
      })
      .then((response) => {
        if(response.status === 200) {
          navigate('/contact/received')
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
    } else {
      if(!email.includes('@')) {
        alert("E-mail non valida!");
      } else if (!termsAndConditions) {
        alert("Accetta i termini e condizioni per continuare.")
      } else {
        alert("Tutti i campi devono essere compilati!");
      }
    }
  };

  return (
    <>
      <Navbar />
      {loadingTrip ? (
        <div className="loading-animation"></div>
      ) : (
        <>
          <div className='trip-container'>
            <br />
            <h1 style={{ textAlign: 'left' }}>Modulo di pre-iscrizione al Viaggio</h1>
            <br />
            <p>Il presente modulo va compilato per ogni partecipante al viaggio, anche se minorenne. NON è una conferma immediata, ma vale come richiesta di iscrizione al viaggio, a cui seguirà una nostra e-mail di conferma. Attendere tale e-mail di conferma prima di procedere al pagamento della quota di partecipazione.</p>
            <br />
            <p>Il viaggio sarà confermato al raggiungimento del numero minimo di partecipanti. In caso di mancato raggiungimento del numero minimo di partecipanti, verrà data comunicazione e verrà rimborsata l’intera quota pagata. In caso di cancellazione del viaggio da parte del partecipante, la quota pagata verrà rimborsata dalla compagnia assicurativa, al netto di costi e franchigie, solo in caso di polizza annullamento emessa. Nulla sarà rimborsato in caso di mancata emissione della polizza annullamento.</p>
            <p><b>Tutti i viaggiatori devono essere in possesso del passaporto.</b></p>
            <br /><br />
            <h3>Il tuo viaggio</h3>
            <br />
            <p style={{ marginTop: '1%', fontWeight: 'bold' }}>Date</p>
            <p style={{ marginTop: '1%' }}>{formatDateItalian(tripData.trip.departure_date)} - {formatDateItalianWithMonthYear(tripData.trip.return_date)}</p>
            <br />
            <p style={{ marginTop: '1%', fontWeight: 'bold' }}>Partecipanti</p>
            <br />
            <div className='trip-book-participants'>
              <div>
                <label htmlFor="quantity_adult" style={{ marginRight: '10px' }}>Adulti (15+)</label>
                <select
                  id="quantity_adult"
                  name="quantity_adult"
                  onChange={handleAdultChange}
                  style={{
                    width: '100%',
                    padding: '10px',
                    borderRadius: '10px',
                    fontSize: '15px'
                  }}
                >
                  {[...Array(10).keys()].map((index) => (
                    <option key={index + 1} value={index + 1}>{index + 1}</option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="quantity_children" style={{ marginRight: '10px', marginLeft: '5%' }}>Bambini (2-14)</label>
                <select
                  id="quantity_children"
                  name="quantity_children"
                  onChange={handleChildrenChange}
                  style={{
                    width: '100%',
                    padding: '10px',
                    borderRadius: '10px',
                    fontSize: '15px',
                    marginLeft: '5%'
                  }}
                >
                  {[...Array(11).keys()].map((index) => (
                    <option key={index} value={index}>{index}</option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="quantity_newborn" style={{ marginRight: '10px', marginLeft: '10%' }}>Neonati (0-1)</label>
                <select
                  id="quantity_newborn"
                  name="quantity_newborn"
                  onChange={handleNewbornChange}
                  style={{
                    width: '100%',
                    padding: '10px',
                    borderRadius: '10px',
                    fontSize: '15px',
                    marginLeft: '10%'
                  }}
                >
                  {[...Array(11).keys()].map((index) => (
                    <option key={index} value={index}>{index}</option>
                  ))}
                </select>
              </div>
            </div>
            {/*ADULT PARTICIPANTS*/}
            {
              (() => {
                const participantInputs = [];
                for (let i = 0; i < adult; i++) {
                  participantInputs.push(
                    <div key={i}>
                      <br /><br />
                      <h3>Partecipante {i + 1} (Adulto)</h3>
                      <br />
                      <p style={{ marginTop: '1%' }}>Nome</p>
                      <input type='text' id={`name-${i}`} name={`name-${i}`} onChange={(event) => handleNameChange(event, i, 'adult')} style={{ width: '50%', padding: '10px', borderRadius: '10px', marginTop: '1%' }} />
                      <br /><br />
                      <p style={{ marginTop: '1%' }}>Cognome</p>
                      <input type='text' id={`surname-${i}`} name={`surname-${i}`} onChange={(event) => handleSurnameChange(event, i, 'adult')} style={{ width: '50%', padding: '10px', borderRadius: '10px', marginTop: '1%' }} />
                      <br /><br />
                      <p style={{ marginTop: '1%' }}>Data di nascita</p>
                      <input type='date' id={`dob-${i}`} name={`dob-${i}`} onChange={(event) => handleDobChange(event, i, 'adult')} style={{ width: '50%', padding: '10px', borderRadius: '10px', marginTop: '1%' }} />
                      <br /><br />
                      <p style={{ marginTop: '1%' }}>Luogo di nascita</p>
                      <input type='text' id={`birthPlace-${i}`} name={`birthPlace-${i}`} onChange={(event) => handleBirthPlaceChange(event, i, 'adult')} style={{ width: '50%', padding: '10px', borderRadius: '10px', marginTop: '1%' }} />
                      <br /><br />
                      <p style={{ marginTop: '1%' }}>Codice Fiscale</p>
                      <input type='text' id={`taxId-${i}`} name={`taxId-${i}`} onChange={(event) => handleTaxIdChange(event, i, 'adult')} style={{ width: '50%', padding: '10px', borderRadius: '10px', marginTop: '1%' }} />
                      <br /><br />
                      <p style={{ marginTop: '1%' }}>Indirizzo di Residenza</p>
                      <input type='text' id={`address-${i}`} name={`address-${i}`} onChange={(event) => handleAddressChange(event, i, 'adult')} style={{ width: '50%', padding: '10px', borderRadius: '10px', marginTop: '1%' }} />
                      <br /><br />
                      <p style={{ marginTop: '1%' }}>Passaporto (Si/No)</p>
                      <input type='text' id={`passport-${i}`} name={`passport-${i}`} onChange={(event) => handlePassportChange(event, i, 'adult')} style={{ width: '50%', padding: '10px', borderRadius: '10px', marginTop: '1%' }} />
                    </div>
                  );
                }
                {/*CHILDREN PARTICIPANTS*/}
                for (let i = adult; i < children + adult; i++) {
                  participantInputs.push(
                    <div key={i}>
                      <br /><br />
                      <h3>Partecipante {i + 1} (Bambino)</h3>
                      <br />
                      <p style={{ marginTop: '1%' }}>Nome</p>
                      <input type='text' id={`name-${i}`} name={`name-${i}`} onChange={(event) => handleNameChange(event, i, 'children')} style={{ width: '50%', padding: '10px', borderRadius: '10px', marginTop: '1%' }} />
                      <br /><br />
                      <p style={{ marginTop: '1%' }}>Cognome</p>
                      <input type='text' id={`surname-${i}`} name={`surname-${i}`} onChange={(event) => handleSurnameChange(event, i, 'children')} style={{ width: '50%', padding: '10px', borderRadius: '10px', marginTop: '1%' }} />
                      <br /><br />
                      <p style={{ marginTop: '1%' }}>Data di nascita</p>
                      <input type='date' id={`dob-${i}`} name={`dob-${i}`} onChange={(event) => handleDobChange(event, i, 'children')} style={{ width: '50%', padding: '10px', borderRadius: '10px', marginTop: '1%' }} />
                      <br /><br />
                      <p style={{ marginTop: '1%' }}>Luogo di nascita</p>
                      <input type='text' id={`birthPlace-${i}`} name={`birthPlace-${i}`} onChange={(event) => handleBirthPlaceChange(event, i, 'children')} style={{ width: '50%', padding: '10px', borderRadius: '10px', marginTop: '1%' }} />
                      <br /><br />
                      <p style={{ marginTop: '1%' }}>Codice Fiscale</p>
                      <input type='text' id={`taxId-${i}`} name={`taxId-${i}`} onChange={(event) => handleTaxIdChange(event, i, 'children')} style={{ width: '50%', padding: '10px', borderRadius: '10px', marginTop: '1%' }} />
                      <br /><br />
                      <p style={{ marginTop: '1%' }}>Indirizzo di Residenza</p>
                      <input type='text' id={`address-${i}`} name={`address-${i}`} onChange={(event) => handleAddressChange(event, i, 'children')} style={{ width: '50%', padding: '10px', borderRadius: '10px', marginTop: '1%' }} />
                      <br /><br />
                      <p style={{ marginTop: '1%' }}>Passaporto (Si/No)</p>
                      <input type='text' id={`passport-${i}`} name={`passport-${i}`} onChange={(event) => handlePassportChange(event, i, 'children')} style={{ width: '50%', padding: '10px', borderRadius: '10px', marginTop: '1%' }} />
                    </div>
                  );
                }
                {/*NEWBORN PARTICIPANTS*/}
                for (let i = (adult + children); i < newborn + children + adult; i++) {
                  participantInputs.push(
                    <div key={i}>
                      <br /><br />
                      <h3>Partecipante {i + 1} (Neonato)</h3>
                      <br />
                      <p style={{ marginTop: '1%' }}>Nome</p>
                      <input type='text' id={`name-${i}`} name={`name-${i}`} onChange={(event) => handleNameChange(event, i, 'newborn')} style={{ width: '50%', padding: '10px', borderRadius: '10px', marginTop: '1%' }} />
                      <br /><br />
                      <p style={{ marginTop: '1%' }}>Cognome</p>
                      <input type='text' id={`surname-${i}`} name={`surname-${i}`} onChange={(event) => handleSurnameChange(event, i, 'newborn')} style={{ width: '50%', padding: '10px', borderRadius: '10px', marginTop: '1%' }} />
                      <br /><br />
                      <p style={{ marginTop: '1%' }}>Data di nascita</p>
                      <input type='date' id={`dob-${i}`} name={`dob-${i}`} onChange={(event) => handleDobChange(event, i, 'newborn')} style={{ width: '50%', padding: '10px', borderRadius: '10px', marginTop: '1%' }} />
                      <br /><br />
                      <p style={{ marginTop: '1%' }}>Luogo di nascita</p>
                      <input type='text' id={`birthPlace-${i}`} name={`birthPlace-${i}`} onChange={(event) => handleBirthPlaceChange(event, i, 'newborn')} style={{ width: '50%', padding: '10px', borderRadius: '10px', marginTop: '1%' }} />
                      <br /><br />
                      <p style={{ marginTop: '1%' }}>Codice Fiscale</p>
                      <input type='text' id={`taxId-${i}`} name={`taxId-${i}`} onChange={(event) => handleTaxIdChange(event, i, 'newborn')} style={{ width: '50%', padding: '10px', borderRadius: '10px', marginTop: '1%' }} />
                      <br /><br />
                      <p style={{ marginTop: '1%' }}>Indirizzo di Residenza</p>
                      <input type='text' id={`address-${i}`} name={`address-${i}`} onChange={(event) => handleAddressChange(event, i, 'newborn')} style={{ width: '50%', padding: '10px', borderRadius: '10px', marginTop: '1%' }} />
                      <br /><br />
                      <p style={{ marginTop: '1%' }}>Passaporto (Si/No)</p>
                      <input type='text' id={`passport-${i}`} name={`passport-${i}`} onChange={(event) => handlePassportChange(event, i, 'newborn')} style={{ width: '50%', padding: '10px', borderRadius: '10px', marginTop: '1%' }} />
                    </div>
                  );
                }
                return participantInputs;
              })()
            }
            <br /><br /><br />
            <h3>Ultime domande</h3>
            <br />
            <p style={{ marginTop: '1%' }}>E-mail</p>
            <input type='email' id={`email`} name={`email`} onChange={handleEmailChange} style={{ width: '50%', padding: '10px', borderRadius: '10px', marginTop: '1%' }} />
            <br /><br />
            <p style={{ marginTop: '1%' }}>Numero di telefono</p>
            <input type='number' id={`phone`} name={`phone`} onChange={handlePhoneChange} style={{ width: '50%', padding: '10px', borderRadius: '10px', marginTop: '1%' }} />
            <br /><br />
            <p style={{ marginTop: '1%' }}>Vuoi aggiungere il volo / Consiglio sul volo?</p>
            <input type='text' id="flight" name="flight" onChange={handleFlightChange} style={{ width: '50%', padding: '10px', borderRadius: '10px', marginTop: '1%' }} />
            <br /><br />
            <p style={{ marginTop: '1%' }}>Se si, da quale città / aeroporto?</p>
            <input type='text' id="flightFrom" name="flightFrom" onChange={handleFlightFromChange} style={{ width: '50%', padding: '10px', borderRadius: '10px', marginTop: '1%' }} />
            <br /><br />
            <p style={{ marginTop: '1%' }}>Vuoi aggiungere trasferimenti?</p>
            <input type='text' id="transfers" name="transfers" onChange={handleTransfersChange} style={{ width: '50%', padding: '10px', borderRadius: '10px', marginTop: '1%' }} />
            <br /><br />
            <p style={{ marginTop: '1%' }}>Codice influencer</p>
            <input type='text' id="influencer" name="influencer" onChange={handleInfluencerChange} style={{ width: '50%', padding: '10px', borderRadius: '10px', marginTop: '1%' }} />
            <br /><br /><br />
            <TermsCheckbox type='termsandconditions' isChecked={termsAndConditions} toggleCheckbox={() => handleTermsCheckboxChange(!termsAndConditions)} />
            <br />
            <button className='button' onClick={handleButtonClick}>Iscriviti</button>
            <br />
          </div>
        </>
      )}
      <Footer />
    </>
  );
}

const formatDateItalianWithMonthYear = (dateString) => {
  const options = { day: 'numeric', month: 'long', year: 'numeric' };
  return new Date(dateString).toLocaleDateString('it-IT', options);
};

const formatDateItalian = (dateString) => {
  const options = { day: 'numeric' };
  return new Date(dateString).toLocaleDateString('it-IT', options);
};

export default TripBook;