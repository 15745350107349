import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import HomeNavbarSearchSmallScreen from './HomeNavbarSearchSmallScreen';
import Image from '../Image';
import WhatsAppButton from '../widgets/WhatsAppButton';

// Icons
import { airplane, call, cart, map, car,footsteps, menu, close } from 'ionicons/icons'; // Importing icons from Ionicons
import { IonIcon } from '@ionic/react'; // Importing IonIcon component from @ionic/react
import { useAuth } from '../../components/AuthContext';

// Styles
import '../../styles/navbar/navbar.css';
import '../../styles/widgets/loadinganimation.css';

function Navbar() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to toggle the menu

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <div className="nav-container">
        <div className='nav'>
          <div className="nav-logo" onClick={() => navigate('/')}>
            <img src="https://api.mylondoncorner.com/images/logo-white.png" alt="Logo" />
          </div>
          <ul className="nav-menu">
            <li><a href="#!" onClick={(e) => { e.preventDefault(); navigate('/tour-di-londra'); }}><IonIcon icon={footsteps} className="nav-icon-big" />Tour</a></li>
            <li><a href="#!" onClick={(e) => { e.preventDefault(); navigate('/itinerari-personalizzati'); }}><IonIcon icon={map} className="nav-icon-big" />Itinerari</a></li>
            <li><a href="#!" onClick={(e) => { e.preventDefault(); navigate('/taxi'); }}><IonIcon icon={car} className="nav-icon-big" />Trasferimenti</a></li>
            <li><a href="#!" onClick={(e) => { e.preventDefault(); navigate('/viaggi-di-gruppo'); }}><IonIcon icon={airplane} className="nav-icon-big" />Viaggi di gruppo</a></li>
            <li><a href="#!" onClick={(e) => { e.preventDefault(); navigate('/contact'); }}><IonIcon icon={call} className="nav-icon-big" />Contattaci</a></li>
            {/* <li><a href="#!" onClick={(e) => { e.preventDefault(); navigate('/tour-di-londra'); }}>Tour</a></li>
            <li><a href="#!" onClick={(e) => { e.preventDefault(); navigate('/itinerari-personalizzati'); }}>Itinerari</a></li>
            <li><a href="#!" onClick={(e) => { e.preventDefault(); navigate('/taxi'); }}>Trasferimenti</a></li>
            <li><a href="#!" onClick={(e) => { e.preventDefault(); navigate('/viaggi-di-gruppo'); }}>Viaggi di gruppo</a></li>
            <li><a href="#!" onClick={(e) => { e.preventDefault(); navigate('/contact'); }}>Contattaci</a></li> */}
            <li>  
              <a href="#!" onClick={(e) => { e.preventDefault(); navigate('/basket'); }}>
                <IonIcon icon={cart} className="nav-icon-big" />
              </a>
            </li>
            <li>
            {user ? (
              <>
                {user.email === 'aleripod@gmail.com' || user.email === 'info@mylondoncorner.com' || user.email === 'andry.righini@gmail.com' ? (
                  <a href="#!" onClick={(e) => { e.preventDefault(); navigate('/admin'); }}>
                    <Image
                      url={"https://api.mylondoncorner.com/images/queens-guard-white.png"}
                      style={{ width: '30px', height: '30px', marginRight: '5px', display: 'inline-block', borderRadius: '50%' }}
                      className='home-nav-icon-big'
                    />
                  </a>
                ) : (null)}
                <a href="#!" onClick={(e) => { e.preventDefault(); navigate('/account'); }}>
                  <Image
                    url={user.photoURL ? user.photoURL : "https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png"}
                    style={{ width: '30px', height: '30px', marginRight: '5px', display: 'inline-block', borderRadius: '50%' }}
                    className='home-nav-icon-big'
                  />
                </a>
              </>
            ) : (
              <a href="#!" onClick={(e) => { e.preventDefault(); navigate('/login'); }}>
                <Image
                  url={"https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png"}
                  style={{ width: '30px', height: '30px', marginRight: '5px', display: 'inline-block', borderRadius: '50%' }}
                  className='home-nav-icon-big'
                />
              </a>
            )}
          </li>
        </ul>
        <IonIcon icon={menu} className="home-nav-menu-icon" onClick={toggleMenu} /> {/* Hamburger icon */}
      </div>
    </div>

    {isMenuOpen && (
      <div className="home-nav-collapsible-menu">
        <IonIcon icon={close} className="home-nav-collapsible-close-icon" onClick={toggleMenu} /> {/* Close icon */}
        <div className="home-nav-collapsible-mlc-icon">
          <img src="https://api.mylondoncorner.com/images/logo-black.png" alt="Logo" />
        </div>
        <br />
        <HomeNavbarSearchSmallScreen />
        <br />
        <ul>
          <li onClick={() => { navigate('/tour-di-londra'); toggleMenu(); }}><IonIcon icon={footsteps} className="nav-icon-big" />Tour</li>
          <li onClick={() => { navigate('/itinerari-personalizzati'); toggleMenu(); }}><IonIcon icon={map} className="nav-icon-big" />Itinerari</li>
          <li onClick={() => { navigate('/taxi'); toggleMenu(); }}><IonIcon icon={car} className="nav-icon-big" />Trasferimenti</li>
          <li onClick={() => { navigate('/viaggi-di-gruppo'); toggleMenu(); }}><IonIcon icon={airplane} className="nav-icon-big" />Viaggi di gruppo</li>
          <li onClick={() => { navigate('/contact'); toggleMenu(); }}><IonIcon icon={call} className="nav-icon-big" />Contattaci</li>
          <li onClick={() => { navigate('/basket'); toggleMenu(); }}><IonIcon icon={cart} className="nav-icon-big" />Carrello</li>
          <li onClick={() => { navigate(user ? '/account' : '/login'); toggleMenu(); }}>{user ? 'Account' : 'Login'}</li>
          {user && (
            (user.email === 'aleripod@gmail.com' || user.email === 'info@mylondoncorner.com' || user.email === 'andry.righini@gmail.com') ? (
              <li onClick={() => { navigate('/admin'); toggleMenu(); }}>
                Admin Panel
              </li>
            ) : null
          )}
        </ul>
      </div>
    )}

    <WhatsAppButton />
  </>
);
}

export default Navbar;
