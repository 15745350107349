import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IonIcon } from '@ionic/react';
import { logoWhatsapp } from 'ionicons/icons';
import '../../styles/widgets/WhatsAppButton.css';

const WhatsAppButton = () => {
  const navigate = useNavigate();

  return (
    <a href="https://api.whatsapp.com/send/?phone=447900221011&text=Ciao+mi+piacerebbe+avere+pi%C3%B9+informazioni+su+Tour+a+Londra+con+Guida+Italiana+https%3A%2F%2Fmylondoncorner.com%2Ftour-di-londra&type=phone_number&app_absent=0">
      <button className="whatsapp-button">
        <IonIcon icon={logoWhatsapp} className="whatsapp-button-icon" />
      </button>
    </a>
  );
};

export default WhatsAppButton;