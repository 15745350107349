import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { useParams, useNavigate } from 'react-router-dom';

import { useAuth } from '../../components/AuthContext';

import Footer from "../../components/Footer";
import Navbar from "../../components/navbar/Navbar";

import SliderImages from '../../components/widgets/SliderImages';
import StickyBottomBar from '../../components/widgets/StickyBottomBar';

import { IonIcon } from '@ionic/react';
import { flagOutline, timeOutline, checkmark, close } from 'ionicons/icons'; // Import the Ionicon clock outline icon

import '../../styles/trip/trip.css';
import NotFound from '../../components/NotFound';

function Trip() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { slug } = useParams(); // Get the slug from URL parameters

  const [tripData, setTripData] = useState([]);
  const [loadingTrip, setLoadingTrip] = useState(true);

  useEffect(() => {
    setLoadingTrip(true);
    axios.get(`https://api.mylondoncorner.com/trip/${slug}`, {
        headers: {
          'X-API-KEY': process.env.REACT_APP_API_KEY // Include the API key in the request headers
        }
      })
      .then((response) => {
        setTripData(response.data);
        setLoadingTrip(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoadingTrip(false);
      });
  }, [slug]);

  const handleButtonClick = (slug) => {
    if(tripData.trip.collaborator_email == "hp@binario934.it") {
      if(tripData.trip.external_form) {
        window.location.href = tripData.trip.external_form
      } else {
        navigate('/trip/' + slug + '/book')
      }
    } else if(tripData.trip.collaborator_email == "uniquexperience@travel-life.it") {
      window.location.href = tripData.trip.external_link
    } else {
      navigate('/trip/' + slug + '/book')
    }
  };

  return (
    <>
      {loadingTrip ? (
        <>
          <Navbar />
          <div className="loading-animation"></div>
          <Footer />
        </>
      ) : (
        tripData.trip != null ? (
          <>
            <Navbar />
            <div className='trip-small-image-header'>
              <SliderImages images={tripData.trip.images_paths} />
              <br />
              <div className='trip-container'>
                <h2>{tripData.trip.name}</h2>
              </div>
            </div>
            <div className='trip-container'>
              <div className='trip-large-image-header'>
                <h2>{tripData.trip.name}</h2>
                <br />
                <div className='row'>
                  <div className='trip-header-column-left'>
                    <img src={'https://api.mylondoncorner.com/' + tripData.trip.images_paths[0]} />
                  </div>
                  <div className='trip-header-column-right row'>
                    <div className='trip-header-column-right'>
                      <img src={'https://api.mylondoncorner.com/' + tripData.trip.images_paths[1]} style={{ paddingLeft: '2%', paddingBottom: '2%' }} />
                    </div>
                    <div className='trip-header-column-right'>
                      <img src={'https://api.mylondoncorner.com/' + tripData.trip.images_paths[2]} style={{ paddingLeft: '2%', paddingBottom: '2%', borderTopRightRadius: '10px' }} />
                    </div>
                    <div className='trip-header-column-right'>
                      <img src={'https://api.mylondoncorner.com/' + tripData.trip.images_paths[3]} style={{ paddingLeft: '2%' }} />
                    </div>
                    <div className='trip-header-column-right'>
                      <img src={'https://api.mylondoncorner.com/' + tripData.trip.images_paths[4]} style={{ paddingLeft: '2%', borderBottomRightRadius: '10px' }} />
                    </div>
                  </div>
                </div>
                <br />
              </div>
              <br />
              <div className='row'>
                <div className='trip-column-desc-left'>
                  <h3>Viaggio di gruppo a {tripData.trip.destination}</h3>
                  <p style={{ fontSize: '14px', marginTop: '1%' }}>{formatDate(tripData.trip.departure_date)} - {formatDate(tripData.trip.return_date)}</p>
                  <br /><br />
                  <h3>da {tripData.trip.price_child != 0 ? tripData.trip.price_child : tripData.trip.price}€ / persona</h3>
                  <br /><br />
                  <hr style={{ borderColor: 'rgba(204, 204, 204, 0.3)' }} />
                  <br /><br />
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IonIcon icon={timeOutline} style={{ fontSize: '25px', marginRight: '10px' }} />
                    <div>
                      <b>Durata</b><br />
                      {calculateNightsBetweenDates(new Date(tripData.trip.departure_date), new Date(tripData.trip.return_date))} notti in hotel a {tripData.trip.hotel_stars} stelle
                    </div>
                  </div>
                  <br />
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IonIcon icon={flagOutline} style={{ fontSize: '25px', marginRight: '10px' }} />
                    <div>
                      <b>Guida</b><br />
                      Italiana
                    </div>
                  </div>
                  <br /><br />
                  <hr style={{ borderColor: 'rgba(204, 204, 204, 0.3)' }} />
                  <br /><br />
                  <p><div dangerouslySetInnerHTML={{ __html: tripData.trip.description }} /></p>
                  <br /><br />
                  <a href={tripData.trip.itinerary_pdf}><button className='button'>Visualizza programma</button></a>
                  <br />
                  <button 
                    className={`button ${tripData.trip.status === 'cancelled' || tripData.trip.status === 'sold out' ? 'red-button' : ''}`} 
                    onClick={() => {
                      if (tripData.trip.status !== 'cancelled' && tripData.trip.status !== 'sold out') {
                        handleButtonClick(tripData.trip.slug);
                      }
                    }}
                  >
                    {tripData.trip.status === 'cancelled' ? 'Cancellato' : tripData.trip.status === 'sold out' ? 'SOLD OUT' : 'Iscriviti'}
                  </button>
                  <br /><br />
                  <hr style={{ borderColor: 'rgba(204, 204, 204, 0.3)' }} />
                  <br /><br />
                  <h3>Cosa è incluso</h3>
                  <br />
                  {tripData.trip.what_included.split("•").slice(1).map(element => (
                    <p key={element}><IonIcon icon={checkmark} style={{ color: 'green', fontSize: '20px' }} /> {element.trim()}</p>
                  ))}
                  <br /><br />
                  <h3>Cosa NON è incluso</h3>
                  <br />
                  {tripData.trip.what_not_included.split("•").slice(1).map(element => (
                    <p key={element}><IonIcon icon={close} style={{ color: 'red', fontSize: '20px' }} /> {element.trim()}</p>
                  ))}
                  <br /><br />
                  <hr style={{ borderColor: 'rgba(204, 204, 204, 0.3)' }} />
                  <br /><br />
                  <h3>Condizioni</h3>
                  <br />
                  {tripData.trip.policy.split("•").slice(1).map(element => (
                    <p key={element}>• {element.trim()}</p>
                  ))}
                  <br /><br />
                  <h3>Termini e condizioni completi</h3>
                  <br />
                  <p>Clicca su <a href={tripData.trip.collaborator_email == "hp@binario934.it" ? "https://mylondoncorner.com/legal/terms-and-conditions-hp" : tripData.trip.collaborator_email == "uniquexperience@travel-life.it" ? "https://www.uniquexperience.it/termini-di-servizio/" : "https://mylondoncorner.com/legal/terms-and-conditions-travel-packages"} style={{ color: "red" }}>questo link</a> per leggere i termini e condizioni completi.</p>
                  <br />
                  <p>Per ulteriori informazioni riempite il modulo di iscrizione nella pagina successiva oppure scriveteci su booking@mylondoncorner.com</p>
                  <br /><br />
                  <hr style={{ borderColor: 'rgba(204, 204, 204, 0.3)' }} />
                  <br /><br />
                  <h3>Perchè partecipare ad un viaggio di gruppo?</h3>
                  <br />
                  <p>I nostri viaggi di gruppo sono ideati per rendere la tua esperienza unica e indimenticabile. Scegliamo sempre gli hotel migliori, le escursioni piu' popolari per la destinazione e la guida in italiano.</p>
                  <br />
                  <p>Generalmente avrete la mattina impegnata con i nostri tour ed i pomeriggi/serate libere per godervi la vacanza secondo le vostre preferenze. Potrete fare nuove amicizie e godere dei vantaggi di dividere i costi con gli altri partecipanti del gruppo.</p>
                  <br />
                  <p>Cosa aspetti? Unisciti ai nostri viaggi di gruppo!</p>
                  <br /><br />
                  <a href={tripData.trip.itinerary_pdf}><button className='button'>Visualizza programma</button></a>
                  <br />
                  <button 
                    className={`button ${tripData.trip.status === 'cancelled' || tripData.trip.status === 'sold out' ? 'red-button' : ''}`} 
                    onClick={() => {
                      if (tripData.trip.status !== 'cancelled' && tripData.trip.status !== 'sold out') {
                        handleButtonClick(tripData.trip.slug);
                      }
                    }}
                  >
                    {tripData.trip.status === 'cancelled' ? 'Cancellato' : tripData.trip.status === 'sold out' ? 'SOLD OUT' : 'Iscriviti'}
                  </button>
                </div>
                <div className='trip-column-desc-right'>
                  <br />
                  <div className='trip-price-box'>
                    <h3><span style={{ fontWeight: '100', fontSize: '17px' }}>da</span> {tripData.trip.price_child != 0 ? tripData.trip.price_child : tripData.trip.price}€ / persona</h3>
                    <br />
                    <button 
                      className={`button ${tripData.trip.status === 'cancelled' || tripData.trip.status === 'sold out' ? 'red-button' : ''}`} 
                      onClick={() => {
                        if (tripData.trip.status !== 'cancelled' && tripData.trip.status !== 'sold out') {
                          handleButtonClick(tripData.trip.slug);
                        }
                      }}
                    >
                    {tripData.trip.status === 'cancelled' ? 'Cancellato' : tripData.trip.status === 'sold out' ? 'SOLD OUT' : 'Iscriviti'}
                  </button>
                    <p style={{ textAlign: 'center', marginTop: '2%', fontSize: '15px' }}>Non riceverai alcun addebito in questa fase</p>
                  </div>
                </div>
              </div>
            </div>
            {/* <StickyBottomBar 
              dates={tripData.trip && formatDateItalian(tripData.trip.departure_date) + '-' + formatDateItalianWithMonth(tripData.trip.return_date)} 
              price={tripData.trip && (tripData.trip.price_child !== 0 ? tripData.trip.price_child + '€' : tripData.trip.price + '€')} 
              buttonText={tripData.trip && (tripData.trip.status === 'open' ? 'Iscriviti' : tripData.trip.status === 'cancelled' ? 'Cancellato' : 'Sold out')} 
              onButtonClick={() => {
                if (tripData.trip && tripData.trip.status !== 'cancelled' && tripData.trip.status !== 'sold out') {
                  handleButtonClick(tripData.trip.slug);
                }                
              }}
              buttonClassName={
                tripData.trip && 
                (tripData.trip.status === 'cancelled' || tripData.trip.status === 'sold out' 
                  ? 'red-button' 
                  : '')
              }              
            /> */}
            <Footer />
          </>
        ) : (
          <NotFound />
        )
      )}
    </>
  );
}

// Define a function to format the date
function formatDate(dateString) {
  const date = new Date(dateString);
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const year = date.getUTCFullYear();
  return `${day}/${month}/${year}`;
}

const formatDateItalianWithMonth = (dateString) => {
  const options = { day: 'numeric', month: 'long' };
  return new Date(dateString).toLocaleDateString('it-IT', options);
};

const formatDateItalian = (dateString) => {
  const options = { day: 'numeric' };
  return new Date(dateString).toLocaleDateString('it-IT', options);
};

function calculateNightsBetweenDates(startDate, endDate) {
  // Convert both dates to milliseconds
  const startMillis = startDate.getTime();
  const endMillis = endDate.getTime();

  // Calculate the difference in milliseconds
  const differenceMillis = Math.abs(endMillis - startMillis);

  // Convert milliseconds to days
  const millisecondsInDay = 1000 * 60 * 60 * 24;
  const nights = Math.ceil(differenceMillis / millisecondsInDay);

  return nights;
}

export default Trip;
