import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useNavigate } from 'react-router-dom';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

// import required modules
import { FreeMode, Pagination, Navigation } from 'swiper/modules';

import '../../styles/components/SliderTours.css';

export default function App({ images }) {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const navigate = useNavigate();

  return (
    <div className="slider-container">
      <button ref={prevRef} className="custom-nav-button prev-button">
        &#8249;
      </button>
      <Swiper
        loop={true}
        breakpoints={{
          320: {
            slidesPerView: 1.5,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 2.5,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
        }}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        // onBeforeInit={(swiper) => {
        //   swiper.params.navigation.prevEl = prevRef.current;
        //   swiper.params.navigation.nextEl = nextRef.current;
        // }}
        modules={[FreeMode, Pagination, Navigation]}
      >
        {images.filter((item) => item.visible === true).map((item) => (
          <SwiperSlide
            key={item._id}
            onClick={() =>
              item.purchasable
                ? (window.location.href = '/tour/' + item.slug)
                : (window.location.href = item.external_link)
            }
          >
            <div className="slider-tours-wrapper">
              <div className="slider-tour-column-image-container">
                <img
                  src={'https://api.mylondoncorner.com/' + item.images_paths[0]}
                  style={{
                    cursor: 'pointer',
                    width: '100%',
                    borderRadius: '10px',
                    border: 'solid 1px #ccc',
                  }}
                  alt="Image"
                />
              </div>
              <p className="slider-tours-p" style={{ color: '#7a7a7a' }}>
                {item.purchasable ? 'TOUR GUIDATO' : 'BIGLIETTO'}
              </p>
              <h4 className="slider-tours-h4">{item.name}</h4>
              <p className="slider-tours-p" style={{ fontSize: '15px' }}>
                {item.duration}
              </p>
              <p className="slider-tours-p">
                {item.car_base_price
                  ? 'Da £' + item.car_base_price + ' a macchina'
                  : item.price_child !== 0
                  ? 'Da £' + item.price_child + ' a persona'
                  : item.price !== 0
                  ? 'Da £' + item.price + ' a persona'
                  : item.onRequest
                  ? 'Da £' + item.price_private
                  : 'GRATIS'}
              </p>
              <br /><br /><br />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <button ref={nextRef} className="custom-nav-button next-button">
        &#8250;
      </button>
    </div>
  );
}