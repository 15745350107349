import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';

// Navigation
import { useNavigate } from 'react-router-dom';

// Components
import HomeNavbar from '../../components/navbar/HomeNavbar';
import Footer from '../../components/Footer';

// Styles
import '../../styles/trip/trips.css';

function Trips() {
  const navigate = useNavigate();

  const [tripsData, setTripsData] = useState([]);
  const [loadingTrips, setLoadingTrips] = useState(true);

  useEffect(() => {
    setLoadingTrips(true);
    axios.get(`https://api.mylondoncorner.com/trips/`, {
      headers: {
        'X-API-KEY': process.env.REACT_APP_API_KEY // Include the API key in the request headers
      }
    })
    .then((response) => {
      setTripsData(response.data);
      setLoadingTrips(false);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      setLoadingTrips(false);
    });
  }, []);

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>Viaggi di Gruppo | MyLondonCorner</title>
        <meta name="description" content="Esplora Londra con i nostri viaggi di gruppo esclusivi in italiano. Itinerari personalizzati, guide esperte, e momenti indimenticabili per vivere il meglio della città!" />
        <link rel="canonical" href="https://mylondoncorner.com/" />

        <meta name="keywords" content="Viaggi di gruppo Londra, Tour Londra italiano, Itinerari Londra, Attrazioni Londra, Esperienze uniche Londra, Guida Londra italiano, Vacanze Londra, Cultura Londra, mylondoncorner" />
        <meta name="robots" content="index, follow, max-image-preview:large" />
        
        {/* Open Graph Tags */}
        <meta property="og:title" content="Tour a Londra in italiano | MyLondonCorner" />
        <meta property="og:description" content="Esplora Londra con i nostri viaggi di gruppo esclusivi in italiano. Itinerari personalizzati, guide esperte, e momenti indimenticabili per vivere il meglio della città!" />
        <meta property="og:image" content="https://api.mylondoncorner.com/images/tour-londra.jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:url" content="https://mylondoncorner.com/" />
        <meta property="og:site_name" content="Tour a Londra in italiano | MyLondonCorner" />
        <meta property="og:locale" content="it_IT" />
        <meta property="og:type" content="website" />
        
        {/* Twitter Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@mylondoncorner" />
        <meta name="twitter:creator" content="@mylondoncorner" />
        <meta name="twitter:title" content="Tour a Londra in italiano | MyLondonCorner" />
        <meta name="twitter:description" content="Esplora Londra con i nostri viaggi di gruppo esclusivi in italiano. Itinerari personalizzati, guide esperte, e momenti indimenticabili per vivere il meglio della città!" />

      </Helmet>
      <HomeNavbar title='Viaggi di gruppo in italiano' description="Scegli l'itinerario che fa per te!" img='https://api.mylondoncorner.com/images/featured-banner-trips.jpg' />
      <br /><br /><br /><br />
      <div className='trips-container'>
        {loadingTrips ? (
          <div className="loading-animation"></div>
        ) : (
          <>
            <h2 style={{ color: '#00247D' }}>VIAGGI IN PARTENZA</h2>
            <br />
            {tripsData.trips
              .filter(item => item.visible === true && new Date() < new Date(item.departure_date))
              .reduce((rows, trip, index) => {
                // Start a new row every 3 trips
                if (index % 3 === 0) rows.push([]);
                rows[rows.length - 1].push(trip);
                return rows;
              }, [])
              .map((row, rowIndex) => (
                <div className='row' key={rowIndex}>
                  {row.map((trip, tripIndex) => (
                    <div className='trips-card' key={tripIndex} onClick={() => navigate('/trip/' + trip.slug)}>
                      <img src={process.env.REACT_APP_API_URL + '/' +  trip.images_paths[0]} />
                      <div className='trips-card-description'>
                        <p>{formatDateItalian(trip.departure_date) + '-' + formatDateItalianWithMonth(trip.return_date)} • {calculateNights(trip.departure_date, trip.return_date)} notti</p>
                        <h3>{trip.name_short}</h3><br />
                        <p>{'Da ' + trip.price + '€ / persona'}</p>
                        <p style={{ color: trip.status === 'sold out' ? 'red' : 'green' }}>
                          {trip.status === 'sold out' ? 'SOLD OUT' : 'APERTO'}
                        </p>
                        <br />
                        <button className='button'>Scopri il viaggio</button>
                        <br />
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            <br /><br />
            <hr />
            <br /><br />
            <h2 style={{ color: '#00247D' }}>VIAGGI PRECEDENTI</h2>
            <br />
            {tripsData.trips
              .filter(item => item.visible === true && new Date() > new Date(item.departure_date))
              .reverse()
              .reduce((rows, trip, index) => {
                // Start a new row every 3 trips
                if (index % 3 === 0) rows.push([]);
                rows[rows.length - 1].push(trip);
                return rows;
              }, [])
              .map((row, rowIndex) => (
                <div className='row' key={rowIndex}>
                  {row.map((trip, tripIndex) => (
                    <div className='trips-card' key={tripIndex} onClick={() => navigate('/trip/' + trip.slug)}>
                      <img src={process.env.REACT_APP_API_URL + '/' +  trip.images_paths[0]} />
                      <div className='trips-card-description'>
                        <p>{formatDateItalian(trip.departure_date) + '-' + formatDateItalianWithMonth(trip.return_date)} • {calculateNights(trip.departure_date, trip.return_date)} notti</p>
                        <h3>{trip.name_short}</h3><br />
                        <p>{'Da ' + trip.price + '€ / persona'}</p>
                        <p style={{ color: trip.status === 'sold out' ? 'red' : 'green' }}>
                          {trip.status === 'sold out' ? 'SOLD OUT' : 'APERTO'}
                        </p>
                        <br />
                        <button className='button'>Scopri il viaggio</button>
                        <br />
                      </div>
                    </div>
                  ))}
                </div>
              ))}
          </>
        )}
      </div>
      <Footer />
    </>
  );
}

const formatDateItalianWithMonth = (dateString) => {
  const options = { day: 'numeric', month: 'long', year: 'numeric' };
  return new Date(dateString).toLocaleDateString('it-IT', options);
};

const formatDateItalian = (dateString) => {
  const options = { day: 'numeric' };
  return new Date(dateString).toLocaleDateString('it-IT', options);
};

function calculateNights(departure_date, return_date) {
  const nights = [];
  let currentDate = new Date(departure_date);

  while (currentDate <= new Date(return_date)) {
    nights.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return nights.length - 1;
}

export default Trips;