import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IonIcon } from '@ionic/react';
import { calendarClearOutline } from 'ionicons/icons';
import '../../styles/widgets/CartCircularButton.css';

const CircularButton = () => {
  const navigate = useNavigate();

  return (
    <button className="cart-circular-button" onClick={() => navigate(`/tour-di-londra`)}>
      <IonIcon icon={calendarClearOutline} className="cart-circular-button-cart-icon" />
      Prenota ora
    </button>
  );
};

export default CircularButton;