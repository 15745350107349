import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../../components/AuthContext';
import { useNavigate } from 'react-router-dom';

import Footer from "../../../components/Footer";
import Navbar from "../../../components/navbar/Navbar";

function AdminDiscountCodes() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [userData, setUserData] = useState({});
  const [loadingUserData, setLoadingUserData] = useState({});

  const [discountCodesData, setDiscountCodesData] = useState({});
  const [loadingDiscountCodesData, setLoadingDiscountCodesData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      if (user && user.uid) {
        try {
          setLoadingUserData(true);
          const response = await axios.get(`https://api.mylondoncorner.com/user/${user.uid}`, {
            headers: {
              'X-API-KEY': process.env.REACT_APP_API_KEY
            }
          });
          setUserData(response.data);
          setLoadingUserData(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoadingUserData(false);
        }
      } else {
        console.log("User is not authenticated or user data is not available.");
      }
    };

    fetchData();
  }, [user]);

  useEffect(() => {
    setLoadingDiscountCodesData(true);
    axios.get(`https://api.mylondoncorner.com/discount-code/all`, {
      headers: {
        'X-API-KEY': process.env.REACT_APP_API_KEY
      }
    })
    .then((response) => {
      setDiscountCodesData(response.data);
      setLoadingDiscountCodesData(false);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      setLoadingDiscountCodesData(false);
    });
  }, []);

  return (
    <div className="table-container">
      {loadingUserData ? (
        <div className="loading-animation"></div>
      ) : (
        Object.keys(userData).length !== 0 ? (
          userData.user.type === 4324143543 ? (
            <>
              <Navbar />

              {loadingDiscountCodesData ? (
                <div className="loading-animation"></div>
              ) : (
                <>
                  <br />
                  <button className='button' onClick={() => navigate('/admin/discount-code/new')}>New</button>
                  <br />
                  <table>
                    <tr>
                      <th>Discount Code</th>
                      <th>Percentage</th>
                      <th>Minimum Spend</th>
                      <th>Max Use Per User</th>
                      <th>Active</th>
                    </tr>
                    {discountCodesData.discountCodes.map((discountCode, index) => (
                      <tr key={index}>
                        <td>{discountCode.code}</td>
                        <td>{discountCode.percentage}</td>
                        <td>{discountCode.minimumSpend}</td>
                        <td>{discountCode.maxUsePerUser}</td>
                        <td>{discountCode.active ? 'true' : 'false'}</td>
                      </tr>
                    ))}
                  </table>                
                </>
              )}

              <Footer />
            </>
          ) : (
            navigate('/')
          )
        ) : (
          navigate('/')
        )
      )}
    </div>
  );
}

export default AdminDiscountCodes;