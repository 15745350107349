import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../../styles/contact.css';

const ContactForm = () => {
  const navigate = useNavigate();

  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("Informazioni");

  const [pageMessage, setPageMessage] = useState("");

  const handleSubmission = async () => {
    if(message !== '' && email !== '' && name !== '' && subject !== '' && email.includes('@')) {
      axios.post('https://api.mylondoncorner.com/contact', {
        subject: subject,
        name: name,
        email: email,
        message: message
      }, {
        headers: {
          'X-API-KEY': process.env.REACT_APP_API_KEY
        }
      })
      .then((response) => {
        if(response.status === 200) {
          navigate('/contact/received')
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
    } else {
      if(!email.includes('@')) {
        setPageMessage({message: "E-mail non valida!", type: "error"});
      } else {
        setPageMessage({message: "Tutti i campi devono essere compilati!", type: "error"});
      }
    }
  };

  return (
    <>
      <div className='contact-container'>
        <h2>Scrivici un messaggio! 💬</h2>
        <br />
        <p>Stai organizzando un viaggio a Londra? Per info sui nostri tour, <strong>esperienze private su misura</strong> o assistenza per un <strong>gruppo numeroso</strong>, siamo qui per te! Compila il modulo o scrivici a <strong>info@mylondoncorner.com</strong></p>
        <br /><br />
        <select onChange={(e) => setSubject(e.target.value)} className='contact-message'>
          <option>Informazioni</option>
          <option>Supporto</option>
          <option>Collaborazione</option>
        </select>
        <br /><br />
        <textarea id="message" name="message" onChange={(e) => setMessage(e.target.value)} rows={8} className='contact-message' placeholder='Qualche domanda? Richiesta particolare? (Obbligatorio)' />
        <br /><br />
        <input type='email' id="email" name="email" onChange={(e) => setEmail(e.target.value)} className='contact-message' placeholder='Inserisci la tua e-mail (Obbligatorio)' />
        <br /><br />
        <input type='text' id="name" name="name" onChange={(e) => setName(e.target.value)} className='contact-message' placeholder='Inserisci il tuo nome (Obbligatorio)' />
        <br /><br />
        <button className='button' onClick={handleSubmission}>Invia</button>
      </div>
    </>
  );
};

export default ContactForm;