import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

// Components
import HomeNavbar from '../../components/navbar/HomeNavbar';
import Footer from '../../components/Footer';
import TourAvailabilityCalendar from '../../components/tour/TourAvailabilityCalendar';
import TrustBox from "../../components/widgets/TrustBox";

// Styles
import '../../styles/tour/OurTours.css';

function OurTours() {
  const navigate = useNavigate();

  const [toursData, setToursData] = useState([]);
  const [loadingTours, setLoadingTours] = useState(true);

  useEffect(() => {
    setLoadingTours(true);
    axios.get(`https://api.mylondoncorner.com/tours/purchasable`, {
      headers: {
        'X-API-KEY': process.env.REACT_APP_API_KEY // Include the API key in the request headers
      }
    })
    .then((response) => {
      setToursData(response.data.tours);
      setLoadingTours(false);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      setLoadingTours(false);
    });
  }, []);

  const generateStructuredData = () => {
    return {
      "@context": "https://schema.org",
      "@graph": [
        {
          "@type": "Organization",
          "@id": "https://mylondoncorner.com/#organization",
          "name": "mylondoncorner",
          "url": "https://mylondoncorner.com/",
          "logo": {
            "@type": "ImageObject",
            "url": "https://api.mylondoncorner.com/images/logo-white.png",
            "width": 500,
            "height": 500
          },
          "sameAs": [
            "https://facebook.com/mylondoncornerpage",
            "https://twitter.com/mylondoncorner",
            "https://instagram.com/mylondoncorner"
          ]
        },
        {
          "@type": "WebPage",
          "@id": "https://mylondoncorner.com/tour-di-londra/#webpage",
          "url": "https://mylondoncorner.com/tour-di-londra",
          "name": "Prenota ora Tour a Londra in italiano | MyLondoncorner",
          "description": "Scopri Londra al massimo con i nostri esclusivi tour guidati in italiano. Visita luoghi nascosti, attrazioni imperdibili e vivi un'esperienza unica con mylondoncorner!",
          "inLanguage": "it-IT",
          "isPartOf": {
            "@id": "https://mylondoncorner.com/#website"
          },
          "primaryImageOfPage": {
            "@id": "https://api.mylondoncorner.com/images/tour-londra.jpg"
          },
          "datePublished": "2024-10-10T10:00:00+00:00",
          "dateModified": "2024-12-10T10:00:00+00:00"
        },
        {
          "@type": "BreadcrumbList",
          "@id": "https://mylondoncorner.com/tour-di-londra/#breadcrumblist",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Home",
              "item": "https://mylondoncorner.com/"
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "Tour a Londra in italiano",
              "item": "https://mylondoncorner.com/tour-di-londra"
            }
          ]
        }
      ]
    };
  };

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>Prenota ora Tour a Londra in italiano | MyLondonCorner</title>
        <meta name="description" content="Prenota oggi il tuo tour esclusivo di Londra con guida italiana. Esplora i luoghi più iconici e vivi un'esperienza unica!" />
        <link rel="canonical" href="https://mylondoncorner.com/tour-di-londra" />

        <meta name="robots" content="index, follow, max-image-preview:large" />
        <meta name="keywords" content="tour di Londra, tour a Londra, guida italiana a Londra, visite guidate a Londra, tour esclusivi a Londra, attrazioni a Londra, tour privati a Londra, cosa vedere a Londra, tour in italiano, tour Harry Potter a Londra, attrazioni iconiche a Londra, tour autentici Londra, Cotswolds, tour inediti a Londra, migliori tour a Londra, il meglio di Londra" />

        {/* Open Graph Tags */}
        <meta property="og:title" content="Prenota ora Tour a Londra in italiano | MyLondonCorner" />
        <meta property="og:description" content="Prenota oggi il tuo tour esclusivo di Londra con guida italiana. Esplora i luoghi più iconici e vivi un'esperienza unica!" />
        <meta property="og:image" content="https://api.mylondoncorner.com/images/tour-londra.jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:url" content="https://mylondoncorner.com/tour-di-londra" />
        <meta property="og:site_name" content="mylondoncorner - Il tuo viaggio a Londra!" />
        <meta property="og:locale" content="it_IT" />
        <meta property="og:type" content="website" />

        {/* Twitter Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@mylondoncorner" />
        <meta name="twitter:creator" content="@mylondoncorner" />
        <meta name="twitter:title" content="Prenota ora Tour a Londra in italiano | MyLondonCorner" />
        <meta name="twitter:description" content="Prenota oggi il tuo tour esclusivo di Londra con guida italiana. Esplora i luoghi più iconici e vivi un'esperienza unica!" />
        <meta name="twitter:image" content="https://api.mylondoncorner.com/images/tour-londra.jpg" />
      </Helmet>

      <script type="application/ld+json">
        {JSON.stringify(generateStructuredData())}
      </script>

      <HomeNavbar title='Tour a Londra in italiano' description='Affidati a noi per viaggiare no-stress!' img='https://api.mylondoncorner.com/images/featured-banner-our-tours.jpg' />
      <br /><br /><br /><br />
      <div className='trips-container'>
        <TrustBox />
        <br /><br />
        {loadingTours ? (
          <div className="loading-animation"></div>
        ) : (
          <>
            {toursData
            .filter(item => item.visible === true)
            .map((tour) => (
              <>
                <div className='our-tours-tour-container' onClick={() => navigate(`/tour/${tour.slug}`)} key={tour._id}>
                  <img src={'https://api.mylondoncorner.com/' + tour.images_paths[0]} loading="lazy" alt="Tour" />
                  <div className='our-tours-tour-details'>
                    <p>{tour.minimumAge === 0 ? 'Adatto a tutte le età' : 'Da ' + tour.minimumAge + ' anni'} • {tour.duration}</p>
                    <h2 style={{ color: '#2869c4' }}>{tour.name}</h2>
                    <br />
                    <p>{tour.short_description}</p>
                    <br />
                    <p>🏷️ {tour.car_base_price ? 'Da £' + tour.car_base_price : tour.price_child != 0 ? 'Da £' + tour.price_child : tour.price != 0 ? 'Da £' + tour.price : tour.onRequest ? 'Da £' + tour.price_private : 'GRATIS' } • 🚩Tour in italiano</p>
                    <br />
                    <button className='button'>Prenota ora</button>
                  </div>
                </div>
                <br />
                <hr />
                <br /><br />
              </>
            ))}
            <div className='our-tours-tour-container' onClick={() => navigate(`/tour-privati`)}>
              <img src={'https://api.mylondoncorner.com/images/guide-private.jpg'} loading="lazy" alt="Tour" />
              <div className='our-tours-tour-details'>
                <p>Adatto a tutte le età • Su richiesta</p>
                <h2>Tour Privati</h2>
                <br />
                <p>Vivi Londra con un tour privato su misura. Scegli le tappe che desideri esplorare, dalla storia ai quartieri più vivaci. La tua guida ti accompagnerà in un'esperienza unica, personalizzata e senza fretta, per scoprire la città a tuo ritmo.</p>
                <br />
                <p>🏷️ Richiedi Preventivo • Tour in italiano</p>
                <br />
                <button className='button'>Prenota ora</button>
              </div>
            </div>
            <br />
            <hr />
            <br /><br />
            <TourAvailabilityCalendar />
            <br />
          </>
        )}
      </div>
      <Footer />
    </>
  );
}

export default OurTours;