import { React, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';

// Components
import HomeNavbar from '../components/navbar/HomeNavbar';
import Footer from '../components/Footer';
import Message from '../components/Message';

// Styles
import '../styles/contact.css';

function Login() {
  const navigate = useNavigate();

  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("Informazioni");

  const [pageMessage, setPageMessage] = useState("");

  const handleSubmission = async () => {
    if(message !== '' && email !== '' && name !== '' && subject !== '' && email.includes('@')) {
      axios.post('https://api.mylondoncorner.com/contact', {
        subject: subject,
        name: name,
        email: email,
        message: message
      }, {
        headers: {
          'X-API-KEY': process.env.REACT_APP_API_KEY
        }
      })
      .then((response) => {
        if(response.status === 200) {
          navigate('/contact/received')
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
    } else {
      if(!email.includes('@')) {
        setPageMessage({message: "E-mail non valida!", type: "error"});
      } else {
        setPageMessage({message: "Tutti i campi devono essere compilati!", type: "error"});
      }
    }
  };

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>Contatti | MyLondonCorner</title>
        <meta name="description" content="Scopri Londra con i nostri esclusivi tour guidati in italiano. Visita luoghi nascosti, attrazioni imperdibili e vivi un'esperienza unica con mylondoncorner!" />
        <link rel="canonical" href="https://mylondoncorner.com/" />

        <meta name="keywords" content="Viaggi a Londra, Turismo a Londra, Attrazioni di Londra, Cose da fare a Londra, Guida di Londra, Vacanza a Londra, Visite turistiche a Londra, Hotel a Londra, Trasporti a Londra, Luoghi famosi di Londra, mylondoncorner" />
        <meta name="robots" content="index, follow, max-image-preview:large" />
        
        {/* Open Graph Tags */}
        <meta property="og:title" content="Contatti | MyLondonCorner" />
        <meta property="og:description" content="Scopri Londra con i nostri esclusivi tour guidati in italiano. Visita luoghi nascosti, attrazioni imperdibili e vivi un'esperienza unica con mylondoncorner!" />
        <meta property="og:image" content="https://api.mylondoncorner.com/images/tour-londra.jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:url" content="https://mylondoncorner.com/" />
        <meta property="og:site_name" content="Contatti | MyLondonCorner" />
        <meta property="og:locale" content="it_IT" />
        <meta property="og:type" content="website" />
        
        {/* Twitter Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@mylondoncorner" />
        <meta name="twitter:creator" content="@mylondoncorner" />
        <meta name="twitter:title" content="Contatti | MyLondonCorner" />
        <meta name="twitter:description" content="Scopri Londra al con i nostri esclusivi tour guidati in italiano. Visita luoghi nascosti, attrazioni imperdibili e vivi un'esperienza unica con mylondoncorner!" />

      </Helmet>
      <HomeNavbar title='Ciao! Come possiamo aiutarti?' description="Scrivici un messaggio!" img='https://api.mylondoncorner.com/images/featured-banner-contact.jpg' />
      <br /><br /><br /><br />
      <div className='contact-container'>
        <h1 className='home-shorter-title'>Informazioni, supporto e collaborazioni</h1>
        <br />
        <h3 style={{ color: '#2e2e2e' }}>Scrivici un messaggio</h3>
        <br /><br />
        <select onChange={(e) => setSubject(e.target.value)} className='contact-message'>
          <option>Informazioni</option>
          <option>Supporto</option>
          <option>Collaborazione</option>
        </select>
        <br /><br />
        <textarea id="message" name="message" onChange={(e) => setMessage(e.target.value)} rows={8} className='contact-message' placeholder='Qualche domanda? Richiesta particolare? (Obbligatorio)' />
        <br /><br />
        <input type='email' id="email" name="email" onChange={(e) => setEmail(e.target.value)} className='contact-message' placeholder='Inserisci la tua e-mail (Obbligatorio)' />
        <br /><br />
        <input type='text' id="name" name="name" onChange={(e) => setName(e.target.value)} className='contact-message' placeholder='Inserisci il tuo nome (Obbligatorio)' />
        <br /><br />
        <button className='button' onClick={handleSubmission}>Invia</button>
      </div>
      <Footer />
    </>
  );
}

export default Login;