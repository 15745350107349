import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import CookieConsent from 'react-cookie-consent';

// Icons
import { medalOutline, chatbubbleEllipsesOutline, shieldCheckmarkOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';

// Components
import HomeNavbar from '../components/navbar/HomeNavbar';
import Footer from '../components/Footer';
import Banner from '../components/widgets/Banner';
import ServiceCard from '../components/ServiceCard';
import SliderTrips from '../components/widgets/SliderTrips';
import NewsletterPopup from '../components/widgets/NewsletterPopup';
import SliderTours from '../components/widgets/SliderTours';
import GoogleReviews from '../components/widgets/GoogleReviews';
import TrustBox from "../components/widgets/TrustBox";
import CartCircularButton from '../components/widgets/CartCircularButton';
import ContactForm from '../components/widgets/ContactForm';

// Styles
import '../styles/home.css';

function Home() {

  const navigate = useNavigate();

  const [tourData, setTourData] = useState([]);
  const [loadingTours, setLoadingTours] = useState(true);

  const [popularToursData, setPopularToursData] = useState([]);
  const [loadingPopularToursData, setLoadingPopularToursData] = useState(true);

  const [tripData, setTripData] = useState([]);
  const [loadingTrips, setLoadingTrips] = useState(true);

  const [postData, setPostData] = useState([]);
  const [loadingPosts, setLoadingPosts] = useState(true);

  useEffect(() => {
    // Make the API call when the component mounts
    axios.get('https://api.mylondoncorner.com/tours/category/name/I%20nostri%20Tour', {
      headers: {
        'X-API-KEY': process.env.REACT_APP_API_KEY // Include the API key in the request headers
      }
    })
    .then((response) => {
      setTourData(response.data.tours); // Update the data state with the API response
      setLoadingTours(false); // Set loading to false once data is fetched
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      setLoadingTours(false); // Set loading to false on error as well
    });

    // Make the API call when the component mounts
    axios.get('https://api.mylondoncorner.com/trips', {
      headers: {
        'X-API-KEY': process.env.REACT_APP_API_KEY // Include the API key in the request headers
      }
    })
    .then((response) => {
      setTripData(response.data.trips); // Update the data state with the API response
      setLoadingTrips(false); // Set loading to false once data is fetched
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      setLoadingTrips(false); // Set loading to false on error as well
    });

    // Make the API call when the component mounts
    axios.get('https://api.mylondoncorner.com/tours/popular', {
      headers: {
        'X-API-KEY': process.env.REACT_APP_API_KEY // Include the API key in the request headers
      }
    })
    .then((response) => {
      setPopularToursData(response.data.tours); // Update the data state with the API response
      setLoadingPopularToursData(false); // Set loading to false once data is fetched
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      setLoadingPopularToursData(false); // Set loading to false on error as well
    });

    // Make the API call when the component mounts
    axios.get('https://api.mylondoncorner.com/posts/limit/6', {
      headers: {
        'X-API-KEY': process.env.REACT_APP_API_KEY // Include the API key in the request headers
      }
    })
    .then((response) => {
      setPostData(response.data.posts); // Update the data state with the API response
      setLoadingPosts(false); // Set loading to false once data is fetched
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      setLoadingPosts(false); // Set loading to false on error as well
    });
    
  }, []);

  useEffect(() => {
    // Load Trustpilot script
    const script = document.createElement('script');
    script.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>Tour a Londra in italiano | MyLondonCorner</title>
        <meta name="description" content="Scopri Londra con i nostri esclusivi tour guidati in italiano. Visita luoghi nascosti, attrazioni imperdibili e vivi un'esperienza unica con mylondoncorner!" />
        <link rel="canonical" href="https://mylondoncorner.com/" />

        <meta name="keywords" content="Viaggi a Londra, Turismo a Londra, Attrazioni di Londra, Cose da fare a Londra, Guida di Londra, Vacanza a Londra, Visite turistiche a Londra, Hotel a Londra, Trasporti a Londra, Luoghi famosi di Londra, mylondoncorner" />
        <meta name="robots" content="index, follow, max-image-preview:large" />
        
        {/* Open Graph Tags */}
        <meta property="og:title" content="Tour a Londra in italiano | MyLondonCorner" />
        <meta property="og:description" content="Scopri Londra con i nostri esclusivi tour guidati in italiano. Visita luoghi nascosti, attrazioni imperdibili e vivi un'esperienza unica con mylondoncorner!" />
        <meta property="og:image" content="https://api.mylondoncorner.com/images/tour-londra.jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:url" content="https://mylondoncorner.com/" />
        <meta property="og:site_name" content="Tour a Londra in italiano | MyLondonCorner" />
        <meta property="og:locale" content="it_IT" />
        <meta property="og:type" content="website" />
        
        {/* Twitter Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@mylondoncorner" />
        <meta name="twitter:creator" content="@mylondoncorner" />
        <meta name="twitter:title" content="Tour a Londra in italiano | MyLondonCorner" />
        <meta name="twitter:description" content="Scopri Londra al con i nostri esclusivi tour guidati in italiano. Visita luoghi nascosti, attrazioni imperdibili e vivi un'esperienza unica con mylondoncorner!" />

      </Helmet>

      <HomeNavbar title='Vivi Londra come un vero Londoner' description='Affidati a noi per viaggiare no-stress!' img='https://api.mylondoncorner.com/images/featured-banner-title.jpg' />

      <br /><br /><br /><br />

      <div className='home-container'>

        <TrustBox />

        <br />

        <h1 className='home-shorter-title'>Scopri Londra con una guida in italiano!</h1>
        <br />
        <hr />
        <br />
        <p>Dal classico Tour di Londra al magico Tour di Harry Potter! Ne abbiamo per tutti, e tu, che tip* sei?</p>
        <br /><br />
        {/* <div>
          {tourData
            .filter(item => item.visible === true) // Filter visible tours
            .slice(0, 3) // Take only the first 3
            .map((tour, index) => (
              <div className='home-tour-column' onClick={() => navigate(`/tour/${tour.slug}`)} key={index}>
                <div className='home-tour-column-image-container'>
                  <img src={`https://api.mylondoncorner.com/${tour.images_paths[0]}`} alt="Image" />
                </div>
                <p>
                  {tour.minimumAge === 0 ? 'Tour in italiano' : 'Da ' + tour.minimumAge + ' anni'} • {tour.duration}
                </p>
                <h4>{tour.name}</h4>
                <p className='home-tour-column-smaller-p'>{tour.short_description}</p>
                <p>
                  {tour.car_base_price ? 'Da £' + tour.car_base_price + ' / macchina' :
                    tour.price_child != 0 ? 'Da £' + tour.price_child + ' / persona' :
                    tour.price != 0 ? 'Da £' + tour.price + ' / persona' : 'GRATIS'}
                </p>
              </div>
            ))
          }
        </div> */}
        <SliderTours images={tourData} />
        <p style={{ color: 'white' }}>I migliori tour di Londra</p>

        {/* <button className='button' onClick={() => { navigate(`/tour-di-londra`) }}>Scopri piú tour</button> */}

        <br /><br /><br />

        <h1 className='home-shorter-title' style={{color: '#2e2e2e'}}>Informazioni utili</h1>
        <br />
        <hr />
        <br />
        <p>Siamo dei veri esperti di Londra e il Regno Unito, scopri cosa ti aspetta!</p>
        <br /><br />
        {loadingPosts ? (
          <div className="loading-animation"></div>
        ) : (
          <div className='row home-blog-post-column-container'>
            {postData.map((post) => (
              <div className='home-blog-post-column' onClick={() => navigate(`/post/${post.slug}`)} key={post.slug}>
                <div className="square-image-container">
                  <img className="square-image" src={'https://api.mylondoncorner.com/' + post.image_path} alt="Blog Post" />
                </div>
                <p>{post.category[0].name.toUpperCase()}</p>
                <h3>{post.title}</h3>
                <br /><br />
              </div>
            ))}
          </div>
        )}

        <br /><br /><br />

        <GoogleReviews />

        <br /><br /><br /><br />

        <h1 className='home-shorter-title' style={{color: '#2e2e2e'}}>Benvenut* su mylondoncorner.com</h1>
        <br />
        <hr />
        <br />
        <p className="home-welcome-text">
          <b>Scopri con noi ogni angolo di Londra</b>, divertiti e organizza la tua visita nella capitale più amata d'Europa. Esplora il nostro sito e <b>lasciati guidare da noi</b> tra teatri, ristoranti, attrazioni e tanto altro ancora per un'<b>esperienza indimenticabile</b>!
        </p>
        <div className="home-welcome-icon-container">
          <div className="home-welcome-icon-box">
            <IonIcon icon={medalOutline} className="home-welcome-icon" />
            <p className="home-welcome-icon-text">Competenza garantita</p>
          </div>
          <div className="home-welcome-icon-box">
            <IonIcon icon={shieldCheckmarkOutline} className="home-welcome-icon" />
            <p className="home-welcome-icon-text">Pagamenti sicuri</p>
          </div>
          <div className="home-welcome-icon-box">
            <IonIcon icon={chatbubbleEllipsesOutline} className="home-welcome-icon" />
            <p className="home-welcome-icon-text">Assistenza in italiano</p>
          </div>
        </div>

        <br /><br /><br /><br /><br />

      </div>

      <Banner 
        title={"Preferisci un Tour Privato?"} 
        description={"Prenota ora una visita privata e personalizzata a ogni tua esigenza!"} 
        link={"https://mylondoncorner.com/tour-privati"}
        linkText={"Prenota ora"}
        imgUrl={"https://api.mylondoncorner.com/images/guide-private.jpg"}
      />

      <div className='home-container'>

        <br /><br /><br /><br />

        <h1 className='home-shorter-title' style={{color: '#2e2e2e'}}>Viaggi di Gruppo</h1>
        <br />
        <hr />
        <br />
        <p>Arricchisci la tua esperienza condividendola con altri viaggiatori come te!</p>
        <br /><br />
        {loadingTrips ? (
          <div className="loading-animation"></div>
        ) : (
          <SliderTrips images={tripData} />
        )}
        <br />
        <button className='button' onClick={() => { navigate(`/viaggi-di-gruppo`) }}>Scopri piú viaggi</button>

        <br /><br /><br /><br /><br />

      </div>

      <Banner 
        title={"Vivi la Magia del Warner Bros. Studio Tour Londra"} 
        description={"Acquista ora i tuoi biglietti e scopri il Mondo Magico!"} 
        link={"https://www.gttickets.com/it/londra/london-warner-bros-studio-tour-london-dietro-le-quinte-di-harry-potter-viaggio-di-andata-ritorno?r=mylondoncorner.gttickets.com"}
        linkText={"Acquista Biglietti"}
        imgUrl={"https://api.mylondoncorner.com/images/uploads/1703704479580--a.jpg"}
      />

      <div className='home-container'>

        <br /><br /><br /><br /><br />

        <h1 className='home-shorter-title' style={{color: '#2e2e2e'}}>Alla ricerca di Attrazioni Popolari?</h1>
        <br />
        <hr />
        <br />
        <p>Scopri cosa Londra ha da offrire! Clicca sul bottone sotto per scoprire più attività...</p>
        <br /><br />
        {loadingPopularToursData ? (
          <div className="loading-animation"></div>
        ) : (
          <SliderTours images={popularToursData} />
        )}
        <br />
        <button className='button' onClick={() => { navigate(`/tours`) }}>Scopri piú attività</button>
        
        <br /><br /><br /><br /><br />

        <h1 className='home-shorter-title' style={{color: '#2e2e2e'}}>Servizi piú richiesti</h1>
        <br />
        <hr />
        <br />
        <div className='services'>
          <ServiceCard 
            img={'https://api.mylondoncorner.com/images/personalised-itineraries.jpg'}
            url={'/itinerari-personalizzati'}
            title={'Itinerari Personalizzati'}
            description={'Richiedi un itinerario su misura per te, ci pensiamo noi!'}
            bgcolor={'#083c84'}
          />
          <ServiceCard 
            img={'https://api.mylondoncorner.com/images/guide-private.jpg'}
            url={'/tour-privati'}
            title={'Guide Private'}
            description={'Richiedi una guida privata che ti accompagnera durante il tuo viaggio'}
            bgcolor={'#083c84'}
          />
          <ServiceCard 
            img={'https://api.mylondoncorner.com/images/taxi.jpg'}
            url={'/taxi'}
            title={'Taxi tra Londra e Aeroporti'}
            description={'Arriva in aeroporto in tempo, comodo e rilassato!'}
            bgcolor={'#083c84'}
          />
        </div>

      </div>

      <br /><br /><br /><br /><br />

      <ContactForm />

      <br /><br />

      <Footer />

      <NewsletterPopup />

      <CookieConsent
        location="bottom"
        buttonText="Ho capito"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px", zIndex: "1000" }}
        expires={1}
        debug={false}
      >
        Questo sito web utilizza i cookie per le sessioni di accesso al fine di migliorare l'esperienza dell'utente e garantire la sicurezza.{" "}
      </CookieConsent>
      <CartCircularButton />
    </>
  );
}

export default Home;